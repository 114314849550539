import { ReactElement } from 'react';
import {
  PrivacyPolicyContainer, PrivacyPolicyText, PPTextContainer, Break,
} from './PrivacyPolicy.styles';

export default function PrivacyPolicy(): ReactElement {
  return (
    <PrivacyPolicyContainer>
      <PPTextContainer>
        <PrivacyPolicyText>
          This privacy policy applies only to the actions of Bearplane, LLC and Users
          with respect to this Website.It does not extend to any websites that can
          be accessed from this Website including, but not limited to, any links we may provide
          to social media websites.
          <Break />
          For purposes of the applicable Data Protection Laws, Bearplane, LLC
          is the "data controller". This means that Bearplane, LLC determines the
          purposes for which, and the manner in which, your Data is processed.
          <Break />
          Data collected
          We may collect the following Data, which includes personal Data, from you:
          email address;
          in each case, in accordance with this privacy policy.
          <Break />
          How we collect Data
          We collect Data in the following ways:
          data is given to us by you; and
          data is collected automatically.
          Data that is given to us by you
          Bearplane, LLC will collect your Data in a number of ways, for example:
          when you contact us through the Website, by telephone, post, e-mail
          or through any other means;
          when you use our services;
          in each case, in accordance with this privacy policy.
          Data that is collected automatically
          To the extent that you access the Website, we will collect your
          Data automatically, for example:
          we automatically collect some information about your visit to the Website.
          This information helps us to make
          improvements to Website content and navigation, and includes your IP address,
          the date, times and frequency with
          which you access the Website and the way you use and interact with its content.
          <Break />
          Our use of Data
          Any or all of the above Data may be required by us from time to time
          in order to provide you with the best possible
          service and experience when using our Website.
          Specifically, Data may be used by us for the following reasons:
          transmission by email of marketing materials that may be of interest to you;
          in each case, in accordance with this privacy policy.
          We may use your Data for the above purposes if we deem it necessary to
          do so for our legitimate interests. If you are
          not satisfied with this, you have the right to object in certain
          circumstances (see the section headed "Your rights"
          below).
          <Break />
          For the delivery of direct marketing to you via e-mail, we'll need your consent,
          whether via an opt-in or soft-opt-in:
          soft opt-in consent is a specific type of consent which applies
          when you have previously engaged with us (for
          soft opt-in consent is a specific type of consent which applies
          when you have previously engaged with us (for
          example, you contact us to ask us for more details about a particular
          product/service, and we are marketing similar
          products/services). Under "soft opt-in" consent, we will
          take your consent as given unless you opt-out.
          for other types of e-marketing, we are required to obtain your
          explicit consent; that is, you need to take positive
          and affirmative action when consenting by, for example,
          checking a tick box that we'll provide.
          If you are not satisfied with our approach to marketing,
          you have the right to withdraw consent at any time. To
          find out how to withdraw your consent, see the section headed "Your rights" below.
          <Break />
          Keeping Data secure
          We will use technical and organisational measures to safeguard your Data, for example:
          access to your account is controlled by a password and a user name that is unique to you.
          we store your Data on secure servers.
          Technical and organisational measures include measures to
          deal with any suspected data breach. If you suspect any
          misuse or loss or unauthorised access to your Data, please
          let us know immediately by contacting us via this e-mail
          address: .
          If you want detailed information from Get Safe Online on
          how to protect your information and your computers and
          devices against fraud, identity theft, viruses and man
          y other online problems, please visit www.getsafeonline.org. Get
          Safe Online is supported by HM Government and leading businesses.
          <Break />
          Data retention
          Unless a longer retention period is required or permitted by law,
          we will only hold your Data on our systems for the
          period necessary to fulfil the purposes outlined in this privacy
          policy or until you request that the Data be deleted.
          Even if we delete your Data, it may persist on backup or archival
          media for legal, tax or regulatory purposes.
          <Break />
          Your rights
          You have the following rights in relation to your Data:
          Right to access - the right to request (i) copies of the information
          we hold about you at any time, or (ii) that we
          modify, update or delete such information. If we provide you with access
          to the information we hold about you,
          we will not charge you for this, unless your request is "manifestly unfounded
          or excessive." Where we are legally
          permitted to do so, we may refuse your request. If we refuse your request,
          we will tell you the reasons why.
          Right to correct - the right to have
          your Data rectified if it is inaccurate or incomplete.
          Right to erase - the right to request that we delete or remove your Data from our systems.
          Right to restrict our use of your Data - the right to "block" us from using
          your Data or limit the way in which
          we can use it.
          <Break />
          Right to data portability - the right to request that we move, copy or transfer your Data.
          Right to object - the right to object to our use of your Data including
          where we use it for our legitimate interests.
          To make inquiries, exercise any of your rights set out above, or withdraw
          your consent to the processing of your Data
          (where consent is our legal basis for processing your Data), please contact us
          via this e-mail address: contact@bearplane.com
          If you are not satisfied with the way a complaint you make in relation to your
          Data is handled by us, you may be able
          to refer your complaint to the relevant data protection authority.
          It is important that the Data we hold about you is accurate and current.
          Please keep us informed if your Data changes
          during the period for which we hold it.
          <Break />
          Links to other websites
          This Website may, from time to time, provide links to other websites.
          We have no control over such websites and are
          not responsible for the content of these websites. This privacy policy does not extend
          to your use of such websites. You
          are advised to read the privacy policy or statement of other websites prior to using them.
          Changes of business ownership and control
          Bearplane, LLC may, from time to time, expand or reduce our business and this may involve
          the sale and/or the
          transfer of control of all or part of Bearplane, LLC. Data provided by Users will,
          where it is relevant to any part of our
          business so transferred, be transferred along with that part and the new owner
          or newly controlling party will, under the
          terms of this privacy policy, be permitted to use the Data for the purposes
          for which it was originally supplied to us.
          We may also disclose Data to a prospective purchaser of our business or any part of it.
          In the above instances, we will take steps with the aim of ensuring your privacy
          is protected.
          <Break />
          General
          You may not transfer any of your rights under this privacy policy to any other person.
          We may transfer our rights
          under this privacy policy where we reasonably believe your rights will not be affected.
          If any court or competent authority finds that any provision of this privacy policy
          (or part of any provision) is invalid,
          illegal or unenforceable, that provision or part-provision will, to the extent required,
          be deemed to be deleted, and the
          validity and enforceability of the other provisions of this privacy policy will
          not be affected.
          Unless otherwise agreed, no delay, act or omission by a party in exercising
          any right or remedy will be deemed a
          waiver of that, or any other, right or remedy.
          This Agreement will be governed by and interpreted according to the
          law of England and Wales. All disputes arising
          under the Agreement will be subject to the exclusive jurisdiction of
          the Colorado courts.
          <Break />
          Changes to this privacy policy
          Bearplane, LLC reserves the right to change this privacy policy as we may deem
          necessary from time to time or as
          may be required by law. Any changes will be immediately posted on the
          Website and you are deemed to have accepted
          the terms of the privacy policy on your first
          use of the Website following the alterations.
          You may contact Bearplane, LLC by email at contact@bearplane.com
        </PrivacyPolicyText>
      </PPTextContainer>
    </PrivacyPolicyContainer>
  );
}
