import styled from 'styled-components';

export const TermsConditionsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #E7D4BC;
  height: calc(100vh - 76px);
  margin-top: 76px;
  /* margin-bottom: 76px; */
  /* overflow-y: scroll; */
`;

export const TermsandConditionsScroll = styled.div`
  overflow-y: scroll;
  height: 100px;
`;

export const TCTextContainer = styled.div`
 width: 60vw;
 height: calc(100vh - 76px);
 padding: 30px;
 overflow-y: scroll;
 margin: 25px;
 border: none;
 border-radius: 10px 10px 10px 10px;
 cursor: text;
 transition: all, 240ms ease-in-out;
 align-items: left;
 justify-content: left;
 background: #193540;
`;

export const TermsConditionsText = styled.div`
 color: #fff;
 font-size: 15px;
 padding: 5px;
 font-weight: 600;
`;

export const Break = styled.div`
  height: 10px;
`;
