import { ReactElement, ReactNode, useRef } from 'react';
import {
  BoxContainer, InfoBoxBackground, InfoBoxContainer,
  TitleText, LeftInfoBoxDecoration, RightInfoBoxDecoration,
  InfoSeparatorLogo,
} from './InfoBox.styles';

export default function InfoBox(
  {
    children, boxTitle, boxColor = '#F0DDC5', justify = 'right', titleColor = '#F0DDC5', bgHeight = '699px', margin = '0px',
  }:
  { children: ReactNode, boxTitle: string, boxColor?: string, justify?: 'right' | 'left', titleColor?: string, bgHeight?: string, margin?: string },
): ReactElement {
  return (
    <BoxContainer>
      <TitleText fontColor={titleColor} margin={margin}>{boxTitle}</TitleText>
      <InfoBoxContainer>
        <LeftInfoBoxDecoration />
        <InfoBoxBackground
          bgColor={boxColor}
          justify={justify}
          bgHeight={bgHeight}
        >
          {children}
        </InfoBoxBackground>
        <RightInfoBoxDecoration />
      </InfoBoxContainer>
      <InfoSeparatorLogo />
    </BoxContainer>
  );
}
