import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BackgroundMLogo } from './img/Logo_M.svg';

interface NavLinkProps {
 disabled: boolean;
}

export const NavContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: space-around;
  z-index: 420;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  width: 100%;

  div {
    width: 100%;
  }
  
  div:nth-child(1) {
    height: 50px;
    background-color: var(--forest-0);
  }

  div:nth-child(2) {
    height: 7px;
    background-color: var(--forest-1);
  }

  div:nth-child(3) {
    height: 2px;
    background-color: var(--yellow-0);
  }

  div:nth-child(4) {
    height: 17px;
    background-color: var(--black-0);
  }
`;

export const HeaderTriangle = styled.div`
  position: absolute;
  left: 4%;
  top: 50px;

  div {
    position: absolute;
  }

  div:nth-child(1) {
    z-index: 5;
    width: 0; 
    height: 0;
    border-left: 85px solid transparent;
    border-right: 85px solid transparent;
    border-top: 85px solid var(--forest-0);
  }

  div:nth-child(2) {
    top: 7px;
    left: -7px;
    z-index: 4;
    width: 0; 
    height: 0;
    border-left: 92px solid transparent;
    border-right: 92px solid transparent;
    border-top: 92px solid var(--forest-1);
  }

  div:nth-child(3) {
    top: 9px;
    left: -9px;
    z-index: 3;
    width: 0; 
    height: 0;
    border-left: 94px solid transparent;
    border-right: 94px solid transparent;
    border-top: 94px solid var(--yellow-0);
  }

  div:nth-child(4) {
    top: 11px;
    left: -30px;
    z-index: 2;
    width: 0; 
    height: 0;
    border-left: 115px solid transparent;
    border-right: 115px solid transparent;
    border-top: 115px solid var(--black-0);
  }
`;

export const NavItemsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: left;
  z-index: 10;

  padding-left: 5%;
  padding-right: 2%;

  svg:first-child {
    transform: translateY(19px);
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 57px;
  align-items: center;
  padding-left: 15px;
`;

export const NavLink = styled(Link)<NavLinkProps>`
  font-family: Sofia Pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  &:focus, &:visited, &:link, &:active {
    text-decoration: none;
  }
  &:hover {
    opacity: 0.75;
  }
  margin-left: 10px;
`;

export const ViewsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const MLogo = styled(BackgroundMLogo)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 5%;
`;
