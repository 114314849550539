import styled from 'styled-components';
import { ReactComponent as SteamLogo } from './img/steamLogo1.svg';
import FooterLinkSteam from './img/whiteSteam.png';
import { ReactComponent as FooterLinkTwitch } from './img/twitch-brands.svg';
import { ReactComponent as FooterLinkDiscord } from './img/discord.svg';

interface ButtonStyleProps {
  bg: string;
}

export const ButtonContainer = styled.button<ButtonStyleProps>`
  display: flex;
  cursor: pointer;
  border: none;
  opacity: 1;
  background-color: ${(p) => p.bg};
  align-items: center;
  padding: 5px 25px;
  margin: 10px 10px;
  border-radius: 5px;
  justify-content: center;
  height: 40px;
  width: 400px;
  @media (max-width: 700px) {
    width: 200px;
   }
   @media (max-width: 900px) {
  width: 300px;
}

  &:hover {
    opacity: 0.75;
  };

`;

export const SteamButtonContainer = styled.button<ButtonStyleProps>`
  display: flex;
  cursor: pointer;
  border: none;
  opacity: 1;
  background-color: ${(p) => p.bg};
  align-items: center;
  padding: 5px 25px;
  margin: 10px 10px;
  border-radius: 5px;
  justify-content: center;
  height: 40px;
  width: 190px;
  @media (max-width: 700px) {
    width: 200px;
   }
   @media (max-width: 900px) {
  width: 150px;
}

  &:hover {
    opacity: 0.75;
  };

`;

export const IconContainer = styled.div`
  transform: translateY(3px);
  margin: 0px 12px 0px 0px;
  height: 21px;
  width: 21px;
  object-fit: cover;
 `;

export const ButtonText = styled.div`
    height: 24px;
    text-align: center;
    font-family: Sofia Pro;
    letter-spacing: 0px;
    color: #FFEC6E;
    text-shadow: 0px 4px 0px #16353F;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    line-height: 31px;
    padding-bottom: 5px;
    @media (max-width: 700px) {
    font-size: 9px;
   }
    @media (max-width: 900px) {
    font-size: 12px;
}
`;

export const SteamButtonText = styled.div`
    height: 24px;
    text-align: center;
    font-family: Sofia Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 4px 0px #16353F;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    line-height: 31px;
    padding-bottom: 5px;
    @media (max-width: 700px) {
    font-size: 9px;
   }
    @media (max-width: 900px) {
    font-size: 12px;
}
`;

export const SteamButtonLogo = styled(SteamLogo)`
  height: 22px;
  width: 22px;
  object-fit: contain;
`;

export const FooterLinkSteamLogo = styled.div`
  background-image: url(${FooterLinkSteam});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: relative;
 
  color: #fff;
  height: 20px;
  width: 20px;
  
`;

export const FooterLinkTwitchLogo = styled(FooterLinkTwitch)`
  color: #fff;
  width: 20px;
  height: 20px;
  padding-top: 2px;
`;

export const FooterLinkDiscordLogo = styled(FooterLinkDiscord)`
  color: #fff;
  width: 22px;
  height: 22px;
`;
