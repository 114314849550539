import styled from 'styled-components';

import Guide from './img/book_02.png';
import { ReactComponent as ShadowGuide } from './img/guideShadow.svg';

export const TheGuide = styled.div`
background-image: url(${Guide});
background-repeat: no-repeat;
background-position: center;
background-size: contain;
z-index: 3;
margin-left: -55px;
position: absolute;
height: 5000px;
width: 5000px;
@media (min-width: 100px) {
        height: 300px;
        margin-top: 5%
    }
    @media (min-width: 360px) {
        height: 300px;
        margin-top: 12%
    }
    @media (min-width: 390px) {
        height: 320px;
        margin-top: 12%
    }
    @media (min-width: 412px) {
        height: 335px;
        margin-top: 10%
    }
    @media (min-width: 435px) {
        height: 350px;
        margin-top: 5%;
    }
    @media (min-width: 700px) {
        height: 350px;
        margin-top: 5%
    }
    @media  (min-width: 701px) and (max-width: 849px){
        height: 325px;
        margin-top: 40%
    }
    @media (min-width: 850px) {
        height: 400px;
        margin-top: 35%
    }
    @media (min-width: 900px) {
        height: 300px;
        margin-top: 50%
    }
    @media (min-width: 1050px) {
        height: 400px;
        margin-top: 25%
    }
    @media (min-width: 1100px) {
        height: 450px;
        margin-top: 20%;
    }
    @media (min-width: 1200px) {
        height: 500px;
        margin-top: 15%;
    }
    @media (min-width: 1275px) {
        height: 575px;
        margin-top: 10%;
    }
    @media (min-width: 1400px) {
        height: 650px;
        margin-top: 2%;
    }
    @media (min-width: 1500px) {
        height: 750px;
        margin-top: -5%;
    }
    @media (min-width: 1800px) {
        height: 750px;
        margin-top: -3%;
    }
    @media (min-width: 2750px) {
        height: 750px;
        margin-top: -2%;
    }
`;

export const GuideShadow = styled(ShadowGuide)`
z-index: 0;
position: absolute;
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: center;
    /* object-fit: cover; */
`;
