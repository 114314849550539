import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as FooterLinkTwitch } from '../Footer/footerImages/twitch-brands.svg';
import { ReactComponent as FooterLinkDiscord } from '../Footer/footerImages/discord.svg';
// import SelectedBackground from './img/SelectedBorder.svg';
// import { ReactComponent as Separator3Line } from './img/Line 3.svg';

interface BoxStyleProps {
  bgColor: string;
}

export const OverlayInfoBoxContainer = styled.div`
background-position: 0 0%;
background-size: cover;
position: relative;
// display: flex;
flex-direction: column;
min-height: 75%;
width: 90%;
max-width: 40%;
justify-content: left;
white-space: pre-wrap;
align-items: left;
word-wrap: break-word;
word-break: break-word;
// padding-right: 25px;
// padding-left: 25px;
margin-right: 50px;
margin-top: 5%;
bottom: 10%;
// overflow-y: scroll;

// ::-webkit-scrollbar {
//   display: none;
// }

@media (min-width: 300px) and (max-width: 900px) {
  width: 100%;
  max-width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  height: 45%;
  bottom: 0%;
  }
  `;

export const OverlayInfoWrapper = styled.div`
position: absolute;
// display: flex;
flex-direction: column;
height: 100%;
width: 85%;
justify-content: left;
align-items: left;
// padding-right: 25px;
padding-left: 25px;
margin-right: 50px;
white-space: pre;
`;

export const ItemHeadlineWrapper = styled.div`
position: relative;
display: flex;
align-items: left;
justify-content: left;
flex-direction: row;
background-color: #F0DDC5;
// background-color: pink;
width: 100%;
height: 10%;

 `;
export const ItemDescriptionWrapper = styled.div`
display: flex;
flex-wrap: wrap;
align-items: left;
justify-content: left;
// flex-direction: row;
background-color: #F0DDC5;
text-align: left;
width: 100%;
min-height: 90%;
position: relative;
// background-color: purple;
overflow-y: auto;
word-wrap: break-word;
word-break: break-word;
white-space: pre-wrap;

// ::-webkit-scrollbar {
//   display: none;
// }
 `;
export const ItemClosingRemarksWrapper = styled.div`
display: flex;
align-items: left;
justify-content: left;
flex-direction: row;
background-color: #F0DDC5;
width: 100%;
height: 10%;
position: relative;
overflow-y: scroll;
::-webkit-scrollbar {
  display: none;
}
 `;

export const TwitchSocialMediaLink = styled.div`
`;

export const DiscordSocialMediaLink = styled.div`
`;

export const FooterLinkTwitchLogo = styled(FooterLinkTwitch)`
  padding-left: 8px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkDiscordLogo = styled(FooterLinkDiscord)`
  padding-left: 15px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const ItemHeadline = styled.div`
  text-decoration: none;
  font-size: 48px;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: black;

  @media (min-width: 300px) and (max-width: 900px) {
    font-size: 12px;
  }
  
  // &:hover {
  //   color: #FFEC6E;
  //   transition: 0.3s ease-out
  // }
`;

// left with this
export const FooterLinks = styled(Link)` 
  color: #6c7e84;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 20;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  
  &:hover {
    color: #FFEC6E;
    transition: 0.3s ease-out
  }
`;
export const ItemDescription = styled.div`
  text-decoration: none;
  font-size: 14px;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: black;
  word-wrap: break-word;

  @media (min-width: 300px) and (max-width: 900px) {
    font-size: 10px;
  }
  
  // &:hover {
  //   color: #FFEC6E;
  //   transition: 0.3s ease-out
  // }
`;
export const ItemRemarks = styled.div`
  text-decoration: none;
  font-size: 16px;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: black;

  @media (min-width: 300px) and (max-width: 900px) {
    font-size: 8px;
  }
  
  // &:hover {
  //   color: #FFEC6E;
  //   transition: 0.3s ease-out
  // }
`;

export const Break1 = styled.div`
  height: 10%;

  @media (min-width: 901px) and (max-width: 1001px) {
    height: 35px;
  }
`;

export const Break2 = styled.div`
  // height: 28%;
  height: 15%;

  @media (min-width: 300px) and (max-width: 900px) {
    height: 1%;
  }
  @media (min-width: 901px) and (max-width: 1000px) {
    height: 18%;
  }
`;

// export const SeparatorLine = styled(Separator3Line)`
// height: 95%;
// `;
