import { ReactElement, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { isAbsolute } from 'path';
import { GalleryThumbnail } from './GalleryThumbnail';
import {
  GalleryContainer, ThumbnailContainer, GalleryImage,
}
  from './Gallery.styles';

const images: string[] = [
  'https://cdn.discordapp.com/attachments/841834653982785546/916439153057595412/Screenshot_2021-12-03_162135.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916425971102519326/AM-JKLVpQDHBdJMhkTnI_mkl8rN2PTepkUr9jvmCElmd0dcgB202AyRnDYf81WXrXT10Q6kiYjiSeKHh-hkv3yfDDNkDFAVmGleWkrZkQuUiZpce_2SZ8KB0faZSiVy4WxUOb0s7SrAweOEA7kuhFOnExFE-w1919-h1080-no.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916425404066168882/Screenshot_2021-12-01_143638.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916426817227882526/AM-JKLV9qWZqkAPDfN3ARpVEZ7bP0phoj9qNhqPV-QzQBodJ3KdUSSfmx0gpF5t860DB3HojiuMNKEaHqzqORDGxfnnt8LiKYZuA6dIZDgFbTYivlKmje4yFoiwAf6Qogc3vPylGO0qQCM1XUKZK3gRzQ0dZw1919-h1080-no.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916430799828549714/Screenshot_2021-12-03_154820.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916425971740049498/AM-JKLWwEE5acoFiClz3FNXceEzvRm_HIdjABDcH9DqSmCcg9yE9Vo6MorCsA_L-xyEgGszJvVMmZdjywOHeGgabqWhZg5LLXjoMk27Q6AKPA6f1jgofYcvoQStDD0CyCwbvuo8tUFnHMLyMzqT2wg66t4YZw1919-h1082-no.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916430073215713380/Screenshot_2021-12-03_154220.png',
  'https://cdn.discordapp.com/attachments/841834653982785546/916427390685700166/AM-JKLV-2MsWE25FMOByiCZKulzC_VyxJjNyiEWr38WkEFdCYT-etx0r8lWG-ho_AxUx0X2kw3WacGObC_PPiMHrhJlfKdPlpyVf1dP79TneefyQiJpBv2frmvXzuZW7iBgc0_os7ggBHrF_XCxVSxA9kWzlw1919-h1082-no.png',

];

export default function Gallery(): ReactElement {
  const controls = useAnimation();
  const thumbnailContainerRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>(images[0]);

  async function selectThumbnail(img: string) {
    await controls.start({ opacity: 0, transition: { duration: 0.25 } });
    setSelectedImage(img);
    thumbnailContainerRef.current.scroll({
      top: 0,
      left: 170 * images.indexOf(img),
      behavior: 'smooth',
    });
    controls.start({ opacity: 1, transition: { duration: 0.25 } });
  }

  return (
    <GalleryContainer>
      <GalleryImage
        as={motion.img}
        src={selectedImage}
        alt="MGK"
        animate={controls}
      />
      <ThumbnailContainer ref={thumbnailContainerRef}>
        {images.map((img) => (
          <GalleryThumbnail
            key={img}
            onClick={() => selectThumbnail(img)}
            isSelected={selectedImage === img}
            image={img}
          />
        ))}
      </ThumbnailContainer>
    </GalleryContainer>
  );
}
