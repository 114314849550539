import { ReactElement } from 'react';
import {
  PressKitContainer, PressKitText,
} from './PressKit.styles';

export default function TermsConditions(): ReactElement {
  return (
    <PressKitContainer>
      <PressKitText>
        Press Kit
      </PressKitText>
    </PressKitContainer>
  );
}
