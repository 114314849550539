import { ReactElement } from 'react';
import { SubviewContainer } from './Home.styles';
import InfoBox from '../Common/InfoBox/InfoBox';
import { PropLogo } from './HeroView.styles';
import { Merchant, Silhouette } from './MerchantView.styles';
import { InfoBoxTextButtonWrapperMerchantGuide, InfoBoxText } from './IntroView.styles';
import { ImageWrapper } from './GuideView.styles';
import PromoButton from '../Common/Button/PromoButton';
import { SteamButtonLogo } from '../Common/Button/PromoButton.styles';

export default function MerchantView(): ReactElement {
  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <SubviewContainer>
      <InfoBox boxTitle="The Merchant" boxColor="#242E2E">
        <ImageWrapper>
          <Silhouette />
          <Merchant />
        </ImageWrapper>
        <InfoBoxTextButtonWrapperMerchantGuide>
          <InfoBoxText>
            <div>
              You play as the Merchant, a fourth-generation storekeeper who has grown a
              reputation in Stonehaven as one who not only cares for the city, but The
              Kingdom as a whole. Many travelers speak of you as a wise and friendly
              merchant who has knowledge of many aspects of the surrounding lands, both
              good and evil. Employing various workers and warriors with a multitude of
              specialties enables you to not only enhance your storefront, but greatly
              increase the prosperity and advancement of all. The knowledge and status
              you have obtained has given you the ability to disseminate quests to the
              heroes of the realm. Continue to trade and supply materials and equipment
              in order to prepare the heroes for battling the surrounding threats. As
              they face these combatants, they will continue to seek further support
              from you, your storefront, and the team of talented individuals dedicated
              to you. The centuries of research that your family has provided you may
              prepare you for certain tasks. Although, there is always a greater evil.
            </div>
          </InfoBoxText>
          <PromoButton
            background="#16353F"
            onClick={() => openTab('https://store.steampowered.com/app/2051640/The_Merchants_Guide_to_the_Kingdom/')}
            icon={<SteamButtonLogo />}
            title="STEAM"
          />
        </InfoBoxTextButtonWrapperMerchantGuide>
      </InfoBox>
    </SubviewContainer>
  );
}
