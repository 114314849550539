import styled from 'styled-components';

export const AppContainer = styled.div`
  text-align: center;
`;

export const AppLogo = styled.img`
  &.App-logo {
    height: 40vmin;
    pointer-events: none;
    animation: App-logo-spin infinite 20s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
