import { ReactElement, useState } from 'react';
import { ContactForm } from './ContactForm';
import { ScrollTo } from './ScrollTo';
import { ScrollToSection } from './ScrollToSection';
import {
  FooterContainer, FooterLogoContainer, FooterMerchLogo, FooterLineLogo, FooterLineLogo1,
  FooterLineLogo2, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinksItems,
  FooterLinksTitle, FooterLinkTwitchLogo, FooterLinkJoinDiscordLogo, FooterJoinLinkContainer,
  FooterBottomBarContainer, SocialMediaLink, FooterTrademark, FooterLinkDiscordLogo,
  FooterLinkTwitterLogo, YoutubeSocialMediaLink, FooterLinkYoutubeLogo, FooterLinkRedditLogo,
  TwitchSocialMediaLink, DiscordSocialMediaLink, TwitterSocialMediaLink, RedditSocialMediaLink,
  FooterLinks, FooterBottomBarWrapper, FooterLinkSteamLogo, SteamSocialMediaLink,
} from './Footer.styles';
import PromoButton from '../Common/Button/PromoButton';
import { ReactComponent as PropertyLogo } from './footerImages/footerPropertyLogo.svg';

interface FooterProps {
  scroll: () => void
}
export default function Footer({ scroll }: FooterProps): ReactElement {
  const handleClick = () => {
    console.log('Back to top');
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  function discordClick() {
    console.log('REDIRECTING TO DISCORD CHANNEL...');
  }

  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <FooterContainer>
      <FooterLogoContainer>
        <FooterMerchLogo />
        <FooterLineLogo />
        <SteamSocialMediaLink>
          <a href="https://store.steampowered.com/app/2051640/The_Merchants_Guide_to_the_Kingdom/" className="steam social">
            <FooterLinkSteamLogo /* onClick={() => openTab('https://steam.com') */ />
          </a>
        </SteamSocialMediaLink>
        <TwitchSocialMediaLink>
          <a href="https://www.twitch.tv/merchantsguide" className="twitch social">
            <FooterLinkTwitchLogo /* onClick={() => openTab('https://twitch.com') */ />
          </a>
        </TwitchSocialMediaLink>
        <DiscordSocialMediaLink>
          <a href="https://discord.gg/2M7tTk7r9g" className="discord social">
            <FooterLinkDiscordLogo />
          </a>
        </DiscordSocialMediaLink>
        <TwitterSocialMediaLink>
          <a href="https://twitter.com/MerchantsGuideK" className="twitter social">
            <FooterLinkTwitterLogo />
          </a>
        </TwitterSocialMediaLink>
        <YoutubeSocialMediaLink>
          <a href="https://www.youtube.com/channel/UCNmPGV-H62fm4YAi9roENGw" className="youtube social">
            <FooterLinkYoutubeLogo />
          </a>
        </YoutubeSocialMediaLink>
        <RedditSocialMediaLink>
          <a href="https://www.reddit.com/r/merchantsguide" className="reddit social">
            <FooterLinkRedditLogo />
          </a>
        </RedditSocialMediaLink>
      </FooterLogoContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinksItems>
              <FooterLinksTitle>THE MERCHANT'S GUIDE TO THE KINGDOM</FooterLinksTitle>
              <ScrollToSection scroll={scroll} />
            </FooterLinksItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinksItems>
              <FooterLinksTitle>COMPANY & LEGAL</FooterLinksTitle>
              <FooterLinks to="/privacypolicy">PRIVACY POLICY</FooterLinks>
              <FooterLinks to="/presskit">PRESS KIT</FooterLinks>
              <FooterLinks to="/termsconditions">TERMS & CONDITIONS</FooterLinks>
              {/* <FooterLinks to="/careers">CAREERS</FooterLinks> */}
              {/* <FooterLinks to="/medium">MEDIUM</FooterLinks> */}
            </FooterLinksItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <ContactForm />
      </FooterWrap>
      <FooterJoinLinkContainer>
        <FooterLineLogo1 />
        {/* <SocialMediaLink>
          <a href="https://www.discord.com" className="discord social">
            <FooterLinkJoinDiscordLogo />
          </a>
        </SocialMediaLink> */}
        <PromoButton
          onClick={() => openTab('https://discord.gg/2M7tTk7r9g')}
          icon={<PropertyLogo />}
          title="JOIN OUR COMMUNITY DISCORD"
        />
        <FooterLineLogo2 />
      </FooterJoinLinkContainer>
      <FooterBottomBarContainer>
        <FooterBottomBarWrapper>
          <FooterTrademark>
            The Merchant's Guide to the Kingdom is a registered trademark of Bearplane, LLC.
          </FooterTrademark>
          <FooterTrademark>
            <span dangerouslySetInnerHTML={{ __html: '&copy;' }} />
            2021 Bearplane, LLC. All rights reserved.
            {/* </FooterTrademarkText> */}
          </FooterTrademark>
        </FooterBottomBarWrapper>
        {/* <button onClick={handleClick}>Test Button</button> */}
        <ScrollTo scroll={scroll} />
      </FooterBottomBarContainer>
    </FooterContainer>
  );
}
