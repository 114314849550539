import { ReactNode, ReactElement } from 'react';
import { ReactComponent as FooterLinkTwitch } from '../Footer/footerImages/twitch-brands.svg';
import { ReactComponent as FooterLinkDiscord } from '../Footer/footerImages/discord.svg';
import { MGKNEWS } from '../../types/MGKN';
import {
  BackgroundContainer, InfoBoxContainer, InfoBoxBackground,
  RegionLinksWrapper, SeparatorLine, Break1, FooterMerchLogo, XButtonContainer,
  LogoContainer, XButton,
} from './NewsOverlay.styles';

import NewsOverlayGallery from './NewsOverlayGallery';
import NewsOverlayInfoBox from './NewsOverlayInfoBox';
import CloseButton from '../Market/CommonMarket/CloseButton';
// import Buying from '../CommonMarket/Buying';
import NewsData from '../../newsdata.json';

interface NewsOverlayProps {
  trigger: boolean;
  boxColor?: string;
  item?: MGKNEWS;
  closeOverlay:() => void
  // setWatching:() => void
}

export default function NewsOverlay(
  {
    boxColor = '#F0DDC5',
    trigger,
    item,
    closeOverlay,
  }:
  NewsOverlayProps,
): ReactElement {
  return (trigger === true) ? (
    <div>
      <BackgroundContainer>
        {/* <RegionLinksWrapper> */}
        {/* <XButtonContainer> */}
        {/* <XButton onClick={closeOverlay}>
              X
            </XButton> */}
        {/* </XButtonContainer> */}
        <LogoContainer>
          <FooterMerchLogo />
        </LogoContainer>
        {/* </RegionLinksWrapper> */}
        <InfoBoxContainer>
          <InfoBoxBackground bgColor={boxColor}>
            <NewsOverlayGallery item={item} />
            <SeparatorLine />
            <NewsOverlayInfoBox item={item} />
          </InfoBoxBackground>
        </InfoBoxContainer>
        <CloseButton onClick={closeOverlay} />
      </BackgroundContainer>
    </div>
  ) : (null);
}
