import styled from 'styled-components';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 76px);
  overflow-y: scroll;
  background-color: #08161C;
  margin-top: 76px;
  overflow-x: hidden;
  // background-color: green;
`;

export const SubviewContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 1100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background-color: red;
`;
