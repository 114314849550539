import { ButtonHTMLAttributes, ReactElement } from 'react';
import {
  ButtonContainer, ButtonText, IconContainer,
} from './PromoButton.styles';

interface PromoButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement;
  background?: string;
}

export default function PromoButton(props: PromoButtonProps): ReactElement {
  const {
    icon, title, background = 'inherit',
  } = props;
  return (
    <ButtonContainer bg={background} {...props}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <ButtonText>{title}</ButtonText>
    </ButtonContainer>
  );
}
