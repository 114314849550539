import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as FooterMLogo } from './footerImages/Path 711.svg';
import { ReactComponent as FooterLLogo } from './footerImages/Line 4.svg';
import FooterLinkSteam from './footerImages/whiteSteam.png';
import { ReactComponent as FooterLinkTwitch } from './footerImages/twitch-brands.svg';
import { ReactComponent as FooterLinkDiscord } from './footerImages/discord.svg';
import { ReactComponent as FooterLinkTwitter } from './footerImages/twitter.svg';
import { ReactComponent as FooterLinkYoutube } from './footerImages/youtube.svg';
import { ReactComponent as FooterLinkReddit } from './footerImages/Reddit.svg';
import { ReactComponent as FooterLLogo1 } from './footerImages/Line 5.svg';
import { ReactComponent as FooterLLogo2 } from './footerImages/Line 6.svg';
import { ReactComponent as FooterLinkJoinDiscord } from './footerImages/Group 718.svg';

export const FooterContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    font-size: 6px;
    min-height: 200px;
  }
`;

export const FooterLogoContainer = styled.div`
  position: relative;
  display: flex;
  width: 85%;
  min-height: 250px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    padding-top: 250px;
    width: 80%;
    font-size: 6px;
    min-height: 50px;
  }
`;

export const FooterMerchLogo = styled(FooterMLogo)`
  width: 500px;
  height: 250px;
`;

export const FooterLineLogo = styled(FooterLLogo)`
  width: 98%;
  height: 4.5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 23px;
`;

export const FooterLineLogo1 = styled(FooterLLogo1)`
  width: 42%;
  height: 25px;
`;

export const FooterLineLogo2 = styled(FooterLLogo2)`
  width: 42%;
  height: 25px;
`;

export const FooterLinkSteamLogo = styled.div`
  background-image: url(${FooterLinkSteam});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: relative;
 
  color: #fff;
  height: 25px;
  width: 25px;
  
`;

export const FooterLinkTwitchLogo = styled(FooterLinkTwitch)`
  padding-left: 8px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkDiscordLogo = styled(FooterLinkDiscord)`
  padding-left: 15px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkTwitterLogo = styled(FooterLinkTwitter)`
  padding-left: 15px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkYoutubeLogo = styled(FooterLinkYoutube)`
  padding-left: 15px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkRedditLogo = styled(FooterLinkReddit)`
  padding-left: 15px;
  color: #fff;
  width: 25px;
  height: 25px;
  padding-top: 25px;
`;

export const FooterLinkJoinDiscordLogo = styled(FooterLinkJoinDiscord)`
  padding-left: 0px;
  height: 50px;
  width: 300;
`;

export const FooterWrap = styled.div`
  display: flex;
  width: 87%;


  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    font-size: 6px;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  min-height: 200px;

  @media screen and (max-width: 700px) {
    padding-top: 20px;
    width: 100%;
    font-size: 12px;
    padding-bottom: 20px;
  }
`;

export const FooterLinksWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    font-size: 12px;
  }
`;

export const FooterLinksItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: left;
  color #fff;
`;

export const FooterLinks = styled(Link)`
  color: #6c7e84;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 20;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  
  &:hover {
    color: #FFEC6E;
    transition: 0.3s ease-out
  }
`;

export const FooterLinksTitle = styled.div`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 15;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
`;

export const FooterJoinLinkContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 0px;
  padding-bottom: 22px

  @media screen and (max-width: 700px) {
    flex-direction: row;
    padding-top: 32px;
    width: 100%;
    font-size: 6px;
  }
`;

export const FooterBottomBarContainer = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  min-height: 150px;
  // justify-content: left;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const FooterBottomBarWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 150px;
  justify-content: flex-end;
  align-items: left;
  flex-direction: column;

  @media screen and (max-width: 740px) {
    font-size: 12px;
    width: 100%;
    // padding-top: 75px;
    
`;

export const SocialMediaLink = styled.div`
  color: #fff;
`;

export const FooterTrademark = styled.div`
  padding-left: 35px;
  margin-right: auto;
  color: #193540;
  font-size: 18;
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 700;

  @media screen and (max-width: 700px) {
    flex-direction: column;
`;

// export const FooterLinkTrademark = styled(FooterTrademark)`
//   margin-right: auto;
//   cursor: pointer;

//   @media screen and (max-width: 600px) {
//     padding-top: 32px;
//     width: 128px;
//     font-size: 6px;
//   }
// `;

export const TwitchSocialMediaLink = styled.div`
`;

export const DiscordSocialMediaLink = styled.div`
`;

export const TwitterSocialMediaLink = styled.div`
`;

export const YoutubeSocialMediaLink = styled.div`
`;

export const RedditSocialMediaLink = styled.div`
`;

export const SteamSocialMediaLink = styled.div`
height: 28px;
width: 28px;
padding-top: 20px;
padding-right: 8px;
`;
