import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as FooterLinkBackToTop } from './footerImages/Group 719.svg';

export const FooterBackToTop = styled(FooterLinkBackToTop)`
  cursor: pointer;

  @media screen and (max-width: 600px) {
    padding-top: 32px;
    width: 128px;
    font-size: 6px;
  }
`;

export const LinkContainer = styled.div`
display: flex;
flex-direction: column;
margin: 16px;
text-align: left;
width: 180px;
box-sizing: border-box;
color #fff;

@media screen and (max-width: 600px) {
  width: 128px;
  font-size: 6px;
}
`;
