import styled from 'styled-components';
import { ReactComponent as MGKBackground } from './img/MGKbackground.svg';
import { ReactComponent as HeroTint } from './img/tintWindow.svg';
import { ReactComponent as MGKHeroLogo } from './img/heroLogo.svg';
import { ReactComponent as PropertyLogo } from './img/propertyLogo.svg';

export const HeroViewContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 768px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  @media (max-width: 700px) {
  width: 100vw;
}
`;

export const PropLogo = styled(PropertyLogo)`
  height: 22px;
  width: 22px;
  object-fit: contain;
`;

export const MGKLogo = styled(MGKHeroLogo)`
  object-fit: cover;
  
  @media (max-width: 700px) {
  width: 300px;
  height: 200px;
`;

export const HeroBackgroundImage = styled(MGKBackground)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index:0;
`;

export const HeroBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index:0;
  @media (max-width: 700px) {
  width: 100%;
  object-fit: contain;
`;

export const HeroVideo = styled.video`
position: relative;
height: 100%;
width: 100%;
object-fit: cover;
z-index:0;
@media (max-width: 700px) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;

export const HeroLogoContainer = styled.div`
  z-index: 2;
  width: 550px;
  height: 317px;
  justify-content: center;
@media (max-width: 700px) {
  width: 300px;
  height: 250px;
}
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  z-index: 2;
`;

export const SeparatorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: -4px;
  z-index: 2;

  div {
    width: 100%;
    background-color: #08161C;
    height: 3px;
  }

  div:nth-child(1) {
    height: 3px;
    margin-bottom: 4px;
  }

  div:nth-child(2) {
    height: 5px;
    margin-bottom: 4px;
  }

  div:nth-child(3) {
    height: 7px;
  }
`;

export const BackgroundTint = styled(HeroTint)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index:1;
  @media (max-width: 700px) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;
