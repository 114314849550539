import { ReactElement } from 'react';
import { MGKNEWS } from '../../types/MGKN';
import {
  LFLContainer, LFLContentWrapper, LFLLocationWrapper, LFLTierWrapper,
  LFLBuyingWrapper, LFLSaleEndsWrapper, ItemHeadline, FullContainer, LFLHeadlineContainer,
  ItemDescriptionHeadline, ItemDateHeadline, LFLHeadlineContentWrapper,
} from './NewsListing.styles';
import Buying from '../Market/CommonMarket/Buying';
import Location from '../Market/CommonMarket/Location';
import SaleEnds from '../Market/CommonMarket/SaleEnds';
import Tier from '../Market/CommonMarket/Tier';
import NewsData from '../../newsdata.json';

export interface ListingProps {
  onClick:() => void
  item:MGKNEWS
  backgroundImage?:string
}

export default function LargeFeaturedListing({
  item, onClick,
}: ListingProps):
ReactElement {
  return (
    <FullContainer>
      <LFLContainer onClick={onClick} backgroundImage={item.images[0]}>
        <LFLContentWrapper justify="left" align="start" padding="0px 0px 5px 40px">
          {/* <LFLSaleEndsWrapper> */}
          <ItemHeadline>
            {/* {item?.title} */}
          </ItemHeadline>
          {/* </LFLSaleEndsWrapper> */}
          <LFLLocationWrapper>
            {/* <Location item={item} large /> */}
          </LFLLocationWrapper>
          <LFLBuyingWrapper>
            {/* <Buying item={item} large /> */}
          </LFLBuyingWrapper>
        </LFLContentWrapper>
        {/* <LFLContentWrapper justify="right" align="end" padding="0px 40px 0px 0px"> */}
        {/* <LFLTierWrapper> */}
        {/* <Tier item={item} large /> */}
        {/* </LFLTierWrapper> */}
        {/* </LFLContentWrapper> */}
      </LFLContainer>
      <LFLHeadlineContainer onClick={onClick} backgroundImage={item.images[0]}>
        <LFLHeadlineContentWrapper justify="left" align="start" padding="0px 0px 5px 40px">
          {/* <LFLSaleEndsWrapper> */}
          <ItemHeadline>
            {item?.title}
          </ItemHeadline>
          <ItemDescriptionHeadline>
            {item?.shortDescription}
          </ItemDescriptionHeadline>
          <ItemDateHeadline>
            {item?.endDayDate}
          </ItemDateHeadline>
          {/* </LFLSaleEndsWrapper> */}
          <LFLLocationWrapper>
            {/* <Location item={item} large /> */}
          </LFLLocationWrapper>
          <LFLBuyingWrapper>
            {/* <Buying item={item} large /> */}
          </LFLBuyingWrapper>
        </LFLHeadlineContentWrapper>
        {/* <LFLContentWrapper justify="right" align="end" padding="0px 40px 0px 0px"> */}
        {/* <LFLTierWrapper> */}
        {/* <Tier item={item} large /> */}
        {/* </LFLTierWrapper> */}
        {/* </LFLContentWrapper> */}
      </LFLHeadlineContainer>
    </FullContainer>
  );
}
