import styled from 'styled-components';

export const FooterEmailContainer = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: right;
  width: 65%;

  @media screen and (max-width: 700px) {
    width: 100%;
    font-size: 6px;
  }
`;

export const FooterEmailForm = styled.form`
  display: flex;
  justify-content: right;

  @media screen and (max-width: 1375px) {
    flex-direction: column;
    padding-top: 32px;
    width: 128px;
    font-size: 6px;
    height: 10px;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 128px;
    font-size: 6px;
    transform: translateY(-50px);
    padding-bottom: 15px;
    padding-right: 55px;
  }
`;

export const FooterEmailBox = styled.input`
  width: 307px;
  height: 44px;
  outline: none;
  border-radius: 6px 6px 6px 6px;
  background: #08161C;
  border-style: solid;
  border-color: #6C7E84;
  color: white;
  font-size: 20px;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  padding-left: 12px;

  ::placeholder {
    color: white;
    opacity: 1;
    text-size: 20px;
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  @media screen and (max-width: 1060px) {
    width: 128px;
    font-size: 16px;
  }

  @media screen and (max-width: 840px) {
    width: 128px;
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    width: 128px;
    font-size: 14px;
  }
  
  @media screen and (min-width: 1060px) and (max-width: 1370px){
    width: 128px;
    font-size: 16px;
  }
`;

export const FooterSubmitButton = styled.button`
  width: 172px;
  height: 44px;
  border: none;
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  transition: all, 240ms ease-in-out;
  align-items: center;
  justify-content: center;
  background-color: #193540;
  transform: translateY(2.5px);
  color: #fff;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-color: #fff;
  

  @media screen and (max-width: 1060px) {
    width: 128px;
    font-size: 14px;
  }

  @media screen and (max-width: 840px) {
    width: 128px;
    font-size: 14px;
  }

  @media screen and (max-width: 700px) {
    width: 128px;
    font-size: 12px;
  }

  @media screen and (max-width: 1060px) {
    width: 128px;
    font-size: 16px;
  }
`;

export const Break1 = styled.div`
  flex-basis: 18px;
  height: 0;
`;

export const Break2 = styled.div`
  flex-basis: 300px;
  height: 0;
`;

export const SubmitButtonText = styled.div`
  color: #fff;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #fff;

  @media screen and (max-width: 840px) {
    width: 128px;
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    width: 128px;
    font-size: 14px;
  }
`;

export const FooterEmailBoxText = styled.div`
  color: #fff;
`;

export const CheckBoxText = styled.div`
  color: #6c7e84;
`;
