import { ReactElement } from 'react';
import {
  HeroViewContainer, HeroBackground, HeroLogoContainer, ButtonContainer,
  SeparatorContainer, BackgroundTint, HeroVideo, MGKLogo, PropLogo,
} from './HeroView.styles';
import PromoButton from '../Common/Button/PromoButton';
import BackgroundVideo from './img/MGKvideo.mp4';

export default function HeroView(): ReactElement {
  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <HeroViewContainer>
      <HeroBackground>
        <HeroVideo autoPlay loop muted playsInline>
          <source src={BackgroundVideo} type="video/mp4" />
        </HeroVideo>
      </HeroBackground>
      <BackgroundTint />
      <HeroLogoContainer>
        <MGKLogo />
      </HeroLogoContainer>

      <ButtonContainer>
        <PromoButton
          onClick={() => openTab('https://discord.gg/2M7tTk7r9g')}
          icon={<PropLogo />}
          title="JOIN OUR COMMUNITY DISCORD"
        />
      </ButtonContainer>

      <SeparatorContainer>
        <div />
        <div />
        <div />
      </SeparatorContainer>
    </HeroViewContainer>
  );
}
