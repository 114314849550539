import { ReactElement } from 'react';

import { SubviewContainer } from './Home.styles';
import InfoBox from '../Common/InfoBox/InfoBox';
import {
  KingdomBG, KingdomContainer, KingdomBGContainer,
  KingdomButtonContainer,
} from './KingdomView.styles';
import { PropLogo } from './HeroView.styles';
import PromoButton from '../Common/Button/PromoButton';
import { ImageWrapper } from './GuideView.styles';
import { InfoBoxTextButtonWrapperMerchantGuide, InfoBoxText } from './IntroView.styles';
import { Silhouette } from './MerchantView.styles';
import { SteamButtonLogo } from '../Common/Button/PromoButton.styles';

// import PrayerStoneImage from '../img/PrayerStone.png';

export default function KingdomView(): ReactElement {
  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // const imgStyle = {
  //   height: 500,
  //   objectFit: 'cover',
  //   paddingTop: 30,
  //   width: 2200,
  //   paddingLeft: 50,
  //   overFlow: 'hidden',
  // };

  return (
    <SubviewContainer>
      <InfoBox boxTitle="The Kingdom" boxColor="#242E2E">
        <ImageWrapper>
          <Silhouette />
          <KingdomBG />
        </ImageWrapper>
        <InfoBoxTextButtonWrapperMerchantGuide>
          <InfoBoxText>
            <div>
              The four elemental stones – Earth, Water, Air, and Fire, are the reason for
              the formation of The Kingdom. The powers they contain draw the
              attention of those who seek to harness the power for themselves. These
              stones have existed as long as life itself and without them, calamity
              would befall. Protect the stones at all cost.
            </div>
          </InfoBoxText>
            &nbsp;
          <PromoButton
            background="#16353F"
            onClick={() => openTab('https://store.steampowered.com/app/2051640/The_Merchants_Guide_to_the_Kingdom/')}
            icon={<SteamButtonLogo />}
            title="STEAM"
          />
        </InfoBoxTextButtonWrapperMerchantGuide>
      </InfoBox>
    </SubviewContainer>
  );
}
