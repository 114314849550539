import styled from 'styled-components';
import { motion } from 'framer-motion';

export const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
    position: relative;
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
    // background-color: pink;
`;

export const ThumbnailContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    // padding: 0px 45px 10px 45px;
    max-width: 100%;
    max-height: 100%;
    height: 115px;
    object-fit: cover;
    overflow-x: scroll;
    &::-webkit-scrollbar {
  display: none; }

`;

export const GalleryImage = styled.img`
    padding: 20px;
    /* height: 451px; */
    width: 802px;
    filter: drop-shadow(0px 6px 10px #947F63);
    align-self: center;
    @media (min-width: 200px) {
    /* height: 250px; */
    width: 300px; 

    @media (min-width: 400px) {
        width: 350px; 
    }
    @media (min-width: 500px) {
        width: 400px; 
    }
    @media (min-width: 600px) {
        width: 500px; 
    }
    @media (min-width: 700px) {
        width: 280px; 
    }
    @media (min-width: 750px) {
        width: 315px; 
    }
    @media (min-width: 800px) {
        width: 360px; 
    }
    @media (min-width: 950px) {
        width: 400px; 
    }
    @media (min-width: 1025px) {
        width: 465px; 
    }
    @media (min-width: 1100px) {
        width: 525px; 
    }
    @media (min-width: 1165px) {
        width: 590px; 
    }
    @media (min-width: 1250px) {
        width: 665px; 
}
`;
