import styled from 'styled-components';

import Tint from './img/tintWindow.svg';

export const TintContainer = styled.div`
 min-height: 100%;
 width: 100%;
 background-image: url(${Tint});
 background-size: 100%;
 background-color: #193540;
 top: 0;
 left: 0;
 opacity: 92%;
 z-index: 1;
 position: absolute;

 @media (min-height: 1800px) and (max-height: 2499px) {
   min-height: 190%;
 }
 @media (min-height: 1500px) and (max-height: 1799px) {
   min-height: 160%;
 }
 @media (min-height: 1250px) and (max-height: 1499px) {
   min-height: 145%;
 }
 @media (min-height: 1100px) and (max-height: 1249px) {
   min-height: 135%;
 }
 @media (min-height: 300px) and (max-height: 1099px) {
   min-height: 365%;
 }
`;
