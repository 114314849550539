// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// // import { ReactComponent as Separator3Line } from './img/Line 3.svg';
// // import { ReactComponent as FooterMLogo } from './img/MerchLogo.svg';
// // import { ReactComponent as BackgroundMLogo } from './img/backgroundMLogo.svg';

// interface BoxStyleProps {
//   bgColor: string;
// }

// export const BackgroundContainer = styled.div`
// background-color: #F0DDC5;
// position: absolute;
// display: flex;
// width: 25vw;
// justify-content: center;
// align-items: center;
// flex-direction: column;
// height: 50px;
// z-index: 10;
// top: 50%;
// left: 50%;
// transform: translate(-50%, -50%);
// overflow-x: hidden;
// padding: 10px;

// &::-webkit-scrollbar {display:none;}

// @media (min-width: 300px) and (max-width: 399px) {
//   width: 95%;
//   height: 95%;
//  }
// @media (min-width: 400px) and (max-width: 5500px) {
//   width: 50%;
//   height: 75%;
//  }
//  `;

// export const InfoBoxContainer = styled.div`
// display: flex;
// flex-direction: column;
// width: 100%;
// height: 100%;
// position: relative;
// padding-left: 25px;
// padding-right: 25px;
// background-color: blue;
// `;

// export const HeadLineWrapper = styled.div`
// display: flex;
// align-items: center;
// justify-content: center;
// background-color: green;
// width: 100%;
// height: 150px;
// position: relative;

// @media (min-width: 300px) and (max-width: 800px) {
//   margin-top: 20%;
//  `;

// export const InfoBoxBackground = styled.div<BoxStyleProps>`
// position: relative;
// display: flex;
// flex-direction: row;
// align-items: center;
// justify-content: left;
// width: 100%;
// height: 100%;
// opacity: 1;
// background-color: purple;

// @media (min-width: 300px) and (max-width: 800px) {
//   flex-direction: column;
//  }
//  @media (min-width: 801px) and (max-width: 900px) {
//   flex-direction: column;
//  }

// `;

// // export const SeparatorLine = styled(Separator3Line)`
// // flex-basis: 150px;
// // height: 85%;
// // `;

// export const Break1 = styled.div`
//   width: -25%;

//   @media (max-width: 700px) {
//     width: 1px;
//   }
// `;

// // export const FooterMerchLogo = styled(FooterMLogo)`
// //   position: relative;

// //   @media (min-width: 300px) and (max-width: 900px) {
// //     width: 50%;
// //     height: 50%
// //   }
// // `;

// export const LogoContainer = styled.div`
//    width: 100%;
//    position: relative;
//    z-index: 1500;
//    margin-top: 76px;
//    background-color: cyan;
// `;

// export const XButtonContainer = styled.div`
//   width: 50px;
//   height: 50px;
//   position: relative;
//   z-index: 500;
//   justify-content: center;
//   align-items: center;
//   left: 48%;

//   @media (min-width: 300px) and (max-width: 1100px) {
//     left: 45%;
//   }
// `;

// export const XButton = styled.div`
//   width: 25px;
//   height: 25px;
//   border: 5px solid rgba(0, 0, 0, 0.05);
//   border-radius: 10px;
//   cursor: pointer;
//   font-size: 20px;
//   font-weight: 700;
//   padding-bottom: 5px;

//   &:hover {
//     color: rgba(255, 255, 255, 0.75);
//   };
// `;

// export const ItemNames = styled.div`
//   text-decoration: none;
//   font-size: 14px;
//   font-family: sofia-pro, sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   color: black;

//   &:hover {
//     color: #FFEC6E;
//     transition: 0.3s ease-out
//   }
// `;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as Separator3Line } from '../Market/Overlay/img/Line 3.svg';
import { ReactComponent as FooterMLogo } from '../Market/Overlay/img/MerchLogo.svg';
import { ReactComponent as BackgroundMLogo } from '../Market/Overlay/img/backgroundMLogo.svg';

interface BoxStyleProps {
  bgColor: string;
}

export const BackgroundContainer = styled.div`
background-image: url(${BackgroundMLogo});
background-color: #F0DDC5;
position: absolute;
display: flex;
width: 25vw;
justify-content: center;
align-items: center;
flex-direction: column;
height: 1080px;
z-index: 10;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
overflow-x: hidden;
padding: 200px;
margin-top: 100px;

&::-webkit-scrollbar {display:none;}

@media (min-width: 300px) and (max-width: 399px) {
  width: 95%;
  height: 95%;
 }
@media (min-width: 400px) and (max-width: 5500px) {
  width: 95%;
  height: 95%;
 }
 `;

export const InfoBoxContainer = styled.div`
// display: flex;
flex-direction: column;
width: 100%;
height: 100%;
position: relative;
padding-left: 25px;
padding-right: 25px;
`;

export const RegionLinksWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: #F0DDC5;
width: 100%;
height: 200px;
position: relative;
margin-top: 176px;

@media (min-width: 300px) and (max-width: 800px) {
  margin-top: 20%;
 `;

export const InfoBoxBackground = styled.div<BoxStyleProps>`
position: relative;
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
width: 100%;
height: 100%;
opacity: 1;

@media (min-width: 300px) and (max-width: 800px) {
  flex-direction: column;
 }
 @media (min-width: 801px) and (max-width: 900px) {
  flex-direction: column;
 }

`;

export const SeparatorLine = styled(Separator3Line)`
flex-basis: 150px;
height: 85%;
`;

export const Break1 = styled.div`
  width: -25%;

  @media (max-width: 700px) {
    width: 1px;
  }
`;

export const FooterMerchLogo = styled(FooterMLogo)`
  position: relative;

  @media (min-width: 300px) and (max-width: 900px) {
    width: 75%;
    height: 75%
  }
`;

export const LogoContainer = styled.div`
   width: 100%;
   position: relative;
   z-index: 1;
   margin-top: 200px;
`;

export const XButtonContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 500;
  justify-content: center;
  align-items: center;
  left: 95%;

  @media (min-width: 300px) and (max-width: 1100px) {
    left: 85%;
  }
`;

export const XButton = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  
  &:hover {
    color: rgba(255, 255, 255, 0.75);
  };
`;
