import { ReactElement } from 'react';
import {
  TintContainer,
} from './Tint.styles';

interface TintProps {
    trigger: boolean;
    onClick:() => void
  }

export default function Tint({ trigger, onClick }: TintProps): ReactElement {
  return (trigger === true) ? (
    <>
      <TintContainer onClick={onClick} />
    </>
  ) : (null);
}
