import { ReactElement, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import SelectedIcon from './img/galleryThumbnailIcon.svg';

interface ThumbnailProps {
    onClick: () => void;
    isSelected: boolean;
    image: string;
}

export function GalleryThumbnail({ onClick, isSelected, image }: ThumbnailProps): ReactElement {
  const imgStyle = {
    border: isSelected === true ? '5px solid #193540' : '',
    opacity: isSelected === true ? 1 : 0.5,
    width: 170,
    height: 96,
    cursor: 'pointer',
    margin: '0px',
  };

  const controls = useAnimation();
  const iconControls = useAnimation();

  useEffect(() => {
    controls.start(isSelected === true
      ? { scale: 1, transition: { duration: 0.3 } }
      : { scale: 0.9, transition: { duration: 0 } });
  }, [isSelected]);

  useEffect(() => {
    iconControls.start(isSelected === true
      ? {
        opacity: 1, scale: 1, y: -3, transition: { behavior: 'smooth', duration: 0.3 },
      }
      : { opacity: 1, transition: { duration: 0 } });
  }, [isSelected]);

  return (
    <div onClick={onClick}>
      {isSelected && (
      <motion.img
        style={{
          opacity: 0.1, scale: 0.7, position: 'absolute', bottom: -3, marginLeft: 84.345,
        }}
        animate={iconControls}
        src={SelectedIcon}
        alt="ICON"
      />
      )}
      <motion.img
        style={imgStyle}
        animate={controls}
        src={image}
        alt="MGK"
      />
    </div>
  );
}
