import { ReactNode, ReactElement } from 'react';
import {
  LowerButtonContainer, CloseButton, CloseButtonImage,
} from './CloseButton.styles';

export default function OverlayCloseButton({ onClick }): ReactElement {
  return (
    <LowerButtonContainer>
      <CloseButton onClick={onClick}>
        <CloseButtonImage />
      </CloseButton>
    </LowerButtonContainer>
  );
}
