import styled from 'styled-components';
import { ReactComponent as BackgroundMLogo } from '../Navigation/img/Logo_M.svg';

export const MarketContainer = styled.div`
  display: flex;  
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 211px);
  overflow-y: scroll;
  background-color: #F4E2CC;
  margin-top: 76px;
  padding: 115px 20px 20px 20px;
  overflow-x: hidden;
  
`;

export const ListingSectionContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 10%;
  // padding: 100px;
  justify-content: center;
  align-items: center;
  // flex-direction: row; 
  background-color: #08161C;
  // background-color: red;
  position: relative;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  // padding: 50px;
`;

export const MLogo = styled(BackgroundMLogo)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 5%;
  z-index: 110;
  `;

export const ViewsContainer = styled.div`
  display: flex;
  width: 100%;
  // height: 100vh;
`;
