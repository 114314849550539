import {
  Link, Switch, Route, BrowserRouter as Router, withRouter,
} from 'react-router-dom';
import { ReactElement } from 'react';
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

import {
  NavContainer, ViewsContainer, HeaderBackground, NavItemsContainer,
  NavLink, NavLinksContainer, HeaderTriangle, MLogo,
} from './Navigation.styles';
import { ReactComponent as Logo } from './img/logo.svg';

import Home from '../Home/Home';
import Market from '../Market/Market';
import News from '../News/News';
import Inventory from '../Inventory/Inventory';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../Footer/TermsConditions';
import PressKit from '../Footer/PressKit';

const navItems: {
  location: string,
  label: string,
  content: ReactElement,
  status: string,
  exact?: boolean,
}[] = [
  {
    location: '/',
    exact: true,
    label: 'Home',
    content: <Home />,
    status: 'active',
  },
  {
    location: '/news',
    exact: true,
    label: 'News',
    content: <News />,
    status: 'active',
  },
  // {
  //   location: '/market',
  //   label: 'Market',
  //   content: <Market />,
  //   status: 'active',
  // },
  {
    location: '/inventory',
    label: 'Inventory',
    content: <Inventory />,
    status: 'disabled',
  },
];

const NavLinks = withRouter(({ location }) => (
  <NavLinksContainer>
    {navItems.map((item) => {
      const color = location.pathname === item.location ? 'var(--yellow-0)' : 'white';
      return (
        <NavLink key={item.label} to={item.location} style={{ color }} disabled={item.status === 'disabled'}>
          {item.label}
        </NavLink>
      );
    })}
  </NavLinksContainer>
));

const NavBackground = (): ReactElement => (
  <>
    <HeaderBackground>
      <div />
      <div />
      <div />
      <div />
    </HeaderBackground>
    <HeaderTriangle>
      <div />
      <div />
      <div />
      <div />
    </HeaderTriangle>
  </>
);

function Navigation(): ReactElement {
  const { wallet } = useWallet();

  return (
    <Router>
      <NavContainer>
        <NavBackground />
        <NavItemsContainer>
          <Logo />
          <NavLinks />
          {/* <WalletModalProvider>
            <WalletMultiButton />
            {wallet && <WalletDisconnectButton />}
          </WalletModalProvider> */}
        </NavItemsContainer>
      </NavContainer>
      <ViewsContainer>
        <MLogo />
        <Switch>
          {navItems.map((item) => (
            <Route key={item.label} exact={item.exact} path={item.location}>
              {item.content}
            </Route>
          ))}
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsconditions" component={TermsConditions} />
          <Route path="/presskit" component={PressKit} />
        </Switch>
      </ViewsContainer>
    </Router>
  );
}

export default Navigation;
