import { ButtonHTMLAttributes, ReactElement } from 'react';
import {
  SteamButtonContainer, SteamButtonText, IconContainer,
} from './PromoButton.styles';

interface PromoButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement;
  background?: string;
}

export default function PromoButton(props: PromoButtonProps): ReactElement {
  const {
    icon, title, background = 'inherit',
  } = props;
  return (
    <SteamButtonContainer bg={background} {...props}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <SteamButtonText>{title}</SteamButtonText>
    </SteamButtonContainer>
  );
}
