import styled from 'styled-components';

import { ReactComponent as DownArrow } from './img/downArrow.svg';
import { ReactComponent as DividingLine } from './img/infoBoxDividingLine.svg';

export const IntroViewContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 1060px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DownArrowContainer = styled(DownArrow)`
  cursor: pointer;
  z-index: 0;
  /* padding: 20px; */
  margin-top: 20px;
`;

export const InfoBoxLine = styled(DividingLine)`
margin-top: 0px;
padding-right: 0px;
z-index: 1;
@media (max-width: 700px) {
    display: none;
}
`;

export const InfoBoxText = styled.div`
min-height: 400px;
width: 80%;
color: #08161C;
z-index: 1;
padding-right: 40px;
padding-bottom: 40px;
padding-left: 40px;
padding-top: 20px;
text-align: justify;
text-justify: inter-word;
font-family: SF Pro Display;
object-fit: fill;
@media (max-width: 700px) {
    min-height: 100px;
    padding-bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
}


div::first-letter {
  float: left;
  font-size: 120px;
  font-weight: normal;
  border: none;
  padding-bottom: 0px;
  margin-right: 15px;
  margin-bottom: -75px;
  margin-top: -35px;
  font-family: blonde-fraktur, serif;
  @media (max-width: 700px) {
    font-size: 80px;
    margin-bottom: -63px;
    margin-top: -20px;
    margin-right: 10px;
  }
  @media (max-width: 412px) {
    font-size: 80px;
    margin-bottom: -55px;
    margin-top: -30px;
    margin-right: 10px;
  }
}
div {
  @media (max-width: 3500px) {
  font-size: 18px;
  font-weight: bold;
}
  @media (max-width: 900px) {
  font-size: 15px;
  font-weight: bold;
}
  @media (max-width: 700px) {
  font-size: 12px;
  font-weight: bold;
}
@media (max-width: 415px) {
  font-size: 10px;
  font-weight: bold;
}
`;

export const IntroInfoBoxText = styled.div`
min-height: 400px;
width: 80%;
color: #08161C;
text-align: justify;
z-index: 1;
padding-right: 40px;
padding-bottom: 20px;
padding-left: 40px;
margin-bottom: 50px;
text-justify: inter-word;
object-fit: fill;
@media (max-width: 700px) {
    min-height: 100px;
    padding-bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
}

div::first-letter {
  float: left;
  font-size: 120px;
  font-weight: normal;
  border: none;
  padding-bottom: 0px;
  margin-right: 15px;
  margin-bottom: -80px;
  margin-top: -35px;
  font-family: blonde-fraktur, serif;
  @media (max-width: 700px) {
    font-size: 80px;
    margin-bottom: -65px;
    margin-top: -20px;
    margin-right: 10px;
  }
  @media (max-width: 412px) {
    font-size: 80px;
    margin-bottom: -55px;
    margin-top: -30px;
    margin-right: 10px;
  }
}
div {
  font-size: 18px;
  font-family: SF Pro Display;
  font-weight: bold;

@media (max-width: 3500px) {
  font-size: 18px;
  font-weight: bold;
}
  @media (max-width: 900px) {
  font-size: 15px;
  font-weight: bold;
}
  @media (max-width: 700px) {
  font-size: 12px;
  font-weight: bold;
}
@media (max-width: 415px) {
  font-size: 10px;
  font-weight: bold;
}
`;

export const IntroUpperButtonContainer = styled.div`
  display: flex;
  width: 350px;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  z-index: 2;
  align-self: center;
  padding-right: 40px;
  padding-bottom: 0px;
`;

export const IntroLowerButtonContainer = styled.div`
  display: flex;
  width: 350px;
  justify-content: left;
  align-items: center;
  min-height: 70px;
  z-index: 1;
  align-self: center;
  padding-right: 40px;
  padding-bottom: 0px;
`;

export const InfoBoxTextButtonWrapperIntroView = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
align-items: left;
background: #F0DDC5;
border-radius: 5px;
border: #E7D4BC 1px;
flex-basis: min-content;
object-fit: contain;
@media (max-width: 700px) {
  padding: 20px; 
  width: 90%;
  object-fit: contain;
  justify-content: center;
align-items: center;
}
@media (max-width: 416px) {
  width: 88%; 
}
@media (max-width: 390px) {
  width: 87%; 
}
@media (max-width: 355px) {
  width: 86%; 
}
@media (max-width: 333px) {
  width: 85%; 
}
`;

export const TopButtonWrapper = styled.div`
display: flex;
flex-direction: row;
height: 100%;
justify-content: center;
align-items: center;
background: #F0DDC5;
border-radius: 5px;
border: #E7D4BC 1px;
flex-basis: min-content;
object-fit: contain;
top: 0px;
@media (max-width: 700px) {
  padding: 20px; 
  width: 90%; 
}
@media (max-width: 416px) {
  width: 88%; 
}
@media (max-width: 390px) {
  width: 87%; 
}
@media (max-width: 355px) {
  width: 86%; 
}
@media (max-width: 333px) {
  width: 85%; 
}
`;

export const InfoBoxTextButtonWrapperMerchantGuide = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
align-items: center;
background: #F0DDC5;
border-radius: 5px;
border: #E7D4BC 1px;
flex-basis: min-content;
object-fit: contain;
@media (max-width: 700px) {
  padding: 20px; 
}
@media (max-width: 412px) {
  width: 89%; 
}
@media (max-width: 390px) {
  width: 89%; 
}
@media (max-width: 360px) {
  width: 88%; 
}
/* @media (min-width: 1800px) {
  width: 25%; */
}
`;

export const IntroButton = styled.button`
  display: flex;
  border: 1px;
  background-color: #08161C;
  color: white;
  align-items: center;
  flex-direction: row;
  height: 44px;
  width: 160px;
  padding: 20px;
  text-align: center;
  font-size: 17px;
  font: Sofia Pro;
  font-weight: bold;
  border-radius: 6px;
  justify-content: center;
 
  //TO ENABLE
  /* opacity: 1; */
  /* cursor: pointer; */
  
  pointer-events: none;
  cursor: none;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  };
`;

export const DiscordIntroButton = styled.button`
  display: flex;
  cursor: pointer;
  border: 1px;
  opacity: 1;
  background-color: #08161C;
  color: white;
  align-items: center;
  flex-direction: row;
  height: 44px;
  width: 160px;
  padding: 20px;
  text-align: center;
  font-size: 17px;
  font: Sofia Pro;
  font-weight: bold;
  border-radius: 6px;
  justify-content: center;

  &:hover {
    opacity: 0.75;
  };
`;
