// import { ReactElement, useState } from 'react';
// import {
//   NewsContainer, NewsText, NewsTextContainer,
// } from './News.styles';
// import NewsOverlay from './NewsOverlay';
// import Tint from '../Market/Overlay/Tint';
// import { MGKNEWS } from '../../types/MGKN';
// import NewsData from '../../newsdata.json';
// import NewsListing from './NewsListing';

// export interface ListingProps {
//   onClick:() => void
//   item:MGKNEWS
//   backgroundImage?:string
// }

// export default function News({
//   item, onClick,
// }: ListingProps): ReactElement {
//   const [selectedItem, setSelectedItem] = useState<MGKNEWS>(null);
//   const shouldDisplayOverlay = selectedItem !== null;

//   return (
//     <NewsContainer>
//       <NewsListing
//         item={item}
//         onClick={() => setSelectedItem(item)}
//       />
//       <NewsTextContainer
//         item={selectedItem}
//         trigger={shouldDisplayOverlay}
//         onClick={() => setSelectedItem(item)}
//       />
//       <Tint
//         trigger={shouldDisplayOverlay}
//         onClick={() => setSelectedItem(null)}
//       />
//       <NewsOverlay
//         item={selectedItem}
//         trigger={shouldDisplayOverlay}
//         closeOverlay={() => setSelectedItem(null)}
//       />
//     </NewsContainer>
//   );
// }

import { ReactElement, useState } from 'react';
import { ListingSectionContainer, ViewsContainer, MLogo } from './NewsContainer.styles';

import { TitleText } from '../Common/InfoBox/InfoBox.styles';
import {
  AllListingsContainer, HeaderListingWrapper, HeaderListing, FeaturedListingWrapper,
  SmallListingsWrapper, TitleSortByWrapper, BorderContainer, Border,
} from './News.styles';
import NewsData from '../../newsdata.json';
import { MGKNEWS } from '../../types/MGKN';
import NewsListing from './NewsListing';
import FeaturedListing from '../Market/FeaturedListing';
import NewsOverlay from './NewsOverlay';
import SmallListing from '../Market/SmallListing';
import SortBy from '../Market/CommonMarket/SortBy';
import Tint from '../Market/Overlay/Tint';

export default function AllListings(): ReactElement {
  const [selectedItem, setSelectedItem] = useState<MGKNEWS>(null);
  const [watchList, setWatchList] = useState([]);
  const featuredItems = NewsData.filter((item) => item.title);
  const shouldDisplayOverlay = selectedItem !== null;
  function addFavorites() {
    if (!watchList.includes(selectedItem)) {
      setWatchList(watchList.concat(0));
      NewsData.splice(0, 0);

      console.log(watchList);
      console.log(NewsData);
    }
  }
  return (
    <>
      <ViewsContainer>
        <MLogo />
        <ListingSectionContainer>
          {/* <TitleText fontColor="#193540" /> */}
          <FeaturedListingWrapper>
            {featuredItems.map((item, index) => {
              if (item.displayVariant === 'premium') {
                return (
                  <NewsListing
                    key={item.title}
                    item={item}
                    onClick={() => setSelectedItem(item)}
                  />
                );
              } return (
                <NewsListing
                  key={item.title}
                  item={item}
                  onClick={() => setSelectedItem(item)}
                />
              );
            })}

          </FeaturedListingWrapper>
        </ListingSectionContainer>
        {/* <ListingSectionContainer />
        <ListingSectionContainer /> */}
        {/* <ListingSectionContainer> */}
        {/* <TitleSortByWrapper> */}
        {/* <TitleText fontColor="#193540" margin="0px 0px 0px 9%">
        ALL EASTMIRE LISTINGS</TitleText> */}
        {/* <SortBy /> */}
        {/* </TitleSortByWrapper> */}
        {/* <SmallListingsWrapper>
          {NewsData.map((item) => (
            <SmallListing
              key={item.title}
              onClick={() => setSelectedItem(item)}
              item={item}
            />
          ))}
        </SmallListingsWrapper> */}
        {/* </ListingSectionContainer> */}
        <Tint
          trigger={shouldDisplayOverlay}
          onClick={() => setSelectedItem(null)}
        />
        <NewsOverlay
          item={selectedItem}
          trigger={shouldDisplayOverlay}
          closeOverlay={() => setSelectedItem(null)}
        />
      </ViewsContainer>
    </>
  );
}
