import styled from 'styled-components';

export const PressKitContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 900px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PressKitText = styled.div`
 width: 300px;
 color: #fff;
 font-size: 15px;
 font-weight: 600;
 border: none;
 border-radius: 10px 10px 10px 10px;
 cursor: pointer;
 transition: all, 240ms ease-in-out;
 align-items: center;
 background: #193540;
`;
