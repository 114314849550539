import { ReactNode, ReactElement } from 'react';
import PromoSteamButton from '../Common/Button/PromoSteamButton';

import {
  OverlayInfoBoxContainer, ItemClosingRemarksWrapper, ItemDescriptionWrapper, ItemHeadline,
  ItemDescription, ItemRemarks, ItemHeadlineWrapper, OverlayInfoWrapper, Break1,
  TwitchSocialMediaLink, DiscordSocialMediaLink, FooterLinkTwitchLogo, FooterLinkDiscordLogo,
  DownArrowContainer,
} from './NewsOverlayInfoBox.styles';

import { MGKNEWS } from '../../types/MGKN';

// import Location from '../CommonMarket/Location';
// import Description from '../CommonMarket/Description';
// import Buying from '../CommonMarket/Buying';
// import Tier from '../CommonMarket/Tier';
// import SaleEnds from '../CommonMarket/SaleEnds';

function openTab(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
}

// interface InfoBoxProps {
//   setWatching?:() => void
// }

export default function NewsOverlayInfoBox({ item }: {item?: MGKNEWS}): ReactElement {
  return (
    <OverlayInfoBoxContainer>
      <OverlayInfoWrapper>
        <ItemHeadlineWrapper>
          <ItemHeadline>
            {item?.title}
          </ItemHeadline>
        </ItemHeadlineWrapper>
        <ItemClosingRemarksWrapper>
          <ItemRemarks>
            {item?.endDayDate}
          </ItemRemarks>
        </ItemClosingRemarksWrapper>
        <ItemDescriptionWrapper>
          <ItemDescription>
            {item?.description}
          </ItemDescription>
        </ItemDescriptionWrapper>
        <PromoSteamButton
          background="#16353F"
          onClick={() => openTab('https://discord.gg/2M7tTk7r9g')}
          title="DISCORD"
        />
        <PromoSteamButton
          background="#16353F"
          onClick={() => openTab('https://twitter.com/MerchantsGuideK')}
          title="TWITTER"
        />

        {/* <Location item={item} overlay />
        <Break1 />
        <Tier item={item} overlay />
        <SaleEnds item={item} overlay />
        <Buying item={item} onClick={onclick} overlay /> */}
      </OverlayInfoWrapper>
      {/* <SeparatorLine />
      <Description item={item} /> */}
    </OverlayInfoBoxContainer>
  );
}
