import styled from 'styled-components';
import { ReactComponent as BoxDecoration } from './img/infoBoxDecoration.svg';
import { ReactComponent as SeparatingLogo } from './img/infoBoxSeparatorLogo.svg';

interface BoxStyleProps {
  bgColor: string;
  justify?: 'right' | 'left';
  bgHeight?: string;
}

interface TitleStyleProps {
  fontColor: string;
  margin?: string;
}

export const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 1000px;
  max-width: 1920px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  // background-color: yellow;
`;

export const TitleText = styled.div<TitleStyleProps>`
  width: 80%;
  height: 48px;
  text-align: left;
  font: normal normal normal 43px/51px Taurunum;
  letter-spacing: 0px;
  color: ${(p) => p.fontColor};
  margin: ${(p) => p.margin};
  opacity: 1;
  padding: 25px 0px 25px 0px;
  @media (max-width: 700px) {
  font-size: 30px;
}
  @media (max-width: 490px) {
  font-size: 26px;
  }
  @media (max-width: 428px) {
  font-size: 22px;
  }
  @media (max-width: 363px) {
  font-size: 18px;
  }
`;

export const InfoBoxContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 90%;
min-height: 708px;
@media (max-width: 700px) {
  width: 90%;
  object-fit: contain;
}
`;

export const LeftInfoBoxDecoration = styled(BoxDecoration)`
padding: 10px;
@media (max-width: 900px) {
  display: none;
}
`;

export const RightInfoBoxDecoration = styled(BoxDecoration)`
padding: 10px;
transform: rotate(180deg);
@media (max-width: 900px) {
  display: none;
}
`;

export const InfoBoxBackground = styled.div<BoxStyleProps>`
display: flex;
flex-direction: row;
align-items: center;
justify-content: ${(p) => p.justify};
width: 90%;
margin-bottom: -20px;
height: ${(p) => p.bgHeight};
background: ${(p) => p.bgColor} 0% 0% no-repeat padding-box;
border: 1px solid #444339;
border-radius: 5px;
opacity: 1;
z-index: 0;
// background-color: red;
@media (max-width: 700px) {
  width: 100%;
  flex-direction: column;
}
`;

export const InfoSeparatorLogo = styled(SeparatingLogo)`
padding: 50px;
`;
