import styled from 'styled-components';
import TheMerchant from './img/Merchant.png';
import { ReactComponent as KingdomSilhouette } from './img/merchantBoxSilhouette.svg';
import { ReactComponent as ShadowMerchant } from './img/merchantShadow.svg';

export const Merchant = styled.div`
background-image: url(${TheMerchant});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
    z-index: 3;
    position: absolute;
    height: 75%;
    width: 75%;
    @media (min-width: 100px) {
        height: 240px;
        margin-top: -15%;
        padding-right:50px;
    }
    @media (min-width: 200px) {
        height: 260px;
        margin-top: -15%;
        padding-right:50px;
    }
    @media (min-width: 240px) {
        height: 360px;
        margin-top: -15%;
        padding-right:50px;
    }
    @media (min-width: 360px) {
        height: 425px;
        margin-top: -15%;
    }
    @media (min-width: 390px) {
        height: 455px;
        margin-top: -15%;
    }
    @media (min-width: 412px) {
        height: 470px;
        margin-top: -16%;
    }
    @media (min-width: 450px) {
        height: 450px;
        margin-top: -15%;
    }
    @media (min-width: 575px) {
        height: 550px;
        margin-top: -15%;
    }
    @media (min-width: 700px) {
        height: 600px;
        margin-top: -2%;
        padding-right: 185px;
    }
    @media (min-width: 850px) {
        height: 600px;
        margin-top: -2%;
        padding-right: 250px;
    }
    @media (min-width: 925px) {
        height: 680px;
        margin-top: -15%;
    }
    @media (min-width: 950px) {
        height: 700px;
        margin-top: -15%;
    }
    @media (min-width: 1020px) {
        height: 770px;
        margin-top: -15%;
    }
    @media (min-width: 1075px) {
        height: 780px;
        margin-top: -15%;
    }
    @media (min-width: 1100px) {
        height: 790px;
        margin-top: -15%;
    }
    @media (min-width: 1240px) {
        height: 800px;
        margin-top: -15%;
    }
    @media (min-width: 1600px) {
        height: 850px;
        margin-top: -13%;
    }
    @media (min-width: 1700px) {
        height: 850px;
        margin-top: -15%;
    }
    @media (min-width: 1850px) {
        height: 875px;
        margin-top: -15%;
    }
    @media (min-width: 1950px) {
        height: 900px;
        margin-top: -15%;
    }
    @media (min-width: 2100px) {
        height: 925px;
        margin-top: -15%;
    }
    @media (min-width: 2250px) {
        height: 950px;
        margin-top: -15%;
    }
    @media (min-width: 2350px) {
        height: 950px;
        margin-top: -13%;
    }
    @media (min-width: 2450px) {
        height: 950px;
        margin-top: -12%;
    }
    @media (min-width: 2650px) {
        height: 950px;
        margin-top: -11%;
    }
    @media (min-width: 2940px) {
        height: 950px;
        margin-top: -10%;
    }
`;

export const Silhouette = styled(KingdomSilhouette)`
    z-index: 2;
    position: absolute;
    width: 100%;
    object-fit: contain;
    bottom: 0;

    @media (min-width: 1600px) {
       height: 400px;
    }

    @media (min-width: 1800px) {
        height: 450px;
     }

     @media (min-width: 1950px) {
        height: 500px;
     }
     
     @media (min-width: 2130px) {
        height: 550px;
     }
     
     @media (min-width: 2350px) {
        height: 600px;
     }
     
     @media (min-width: 2510px) {
        height: 650px;
     }
      
     @media (min-width: 2730px) {
        height: 675px;
     }
`;

export const MerchantShadow = styled(ShadowMerchant)`
    z-index: 1;
    position: absolute;
`;
