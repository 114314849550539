import { ReactElement, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {
  FooterEmailForm, FooterEmailBox, FooterEmailContainer,
  FooterSubmitButton, Break1, Break2, SubmitButtonText, CheckBoxText,
} from './ContactForm.styles';

export function ContactForm(): ReactElement {
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [state, handleSubmit] = useForm('mgerbnkn');
  if (state.succeeded) {
    return <FooterEmailContainer><p>Thanks for joining</p></FooterEmailContainer>;
  }
  return (
    <FooterEmailContainer>
      <FooterEmailForm action="https://formspree.io/f/mgerbnkn" onSubmit={handleSubmit}>
        <Break2 />
        <FooterEmailBox type="email" value={email} name="email" placeholder=" Enter Email" onChange={(e) => setEmail(e.target.value)} />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <Break1 />
        <FooterSubmitButton type="submit" disabled={state.submitting}>
          Subscribe
          {/* <SubmitButtonText>Subscribe</SubmitButtonText> */}
        </FooterSubmitButton>
      </FooterEmailForm>
      {/* <input
        style={{
          marginBottom: 100,
        }}
        type="checkbox"
        onChange={(e) => setNewsletter(e.target.checked)}
      /> */}
      {/* <CheckBoxText>JOIN THE MERCHANT'S GUIDE NEWSLETTER TO STAY UPDATED</CheckBoxText> */}
    </FooterEmailContainer>
  );
}
