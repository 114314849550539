import { ReactElement, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { NewsOverlayGalleryThumbnail } from './NewsOverlayGalleryThumbnail';
import {
  GalleryContainer, ThumbnailContainer, InfoSeparatorLogo,
  BackgroundContainer, GalleryImage,
}
  from './NewsOverlayGallery.styles';
import { MGKNEWS } from '../../types/MGKN';

export default function NewsOverlayGallery({ item }: {item: MGKNEWS}): ReactElement {
  const controls = useAnimation();
  const NewsthumbnailContainerRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>(item.images[0]);
  async function selectThumbnail(img: string) {
    await controls.start({ opacity: 0, transition: { duration: 0.25 } });
    setSelectedImage(img);
    NewsthumbnailContainerRef.current.scroll({
      top: 0,
      left: 170 * item.images.indexOf(img),
      behavior: 'smooth',
    });
    controls.start({ opacity: 1, transition: { duration: 0.25 } });
  }
  return (
    <GalleryContainer>
      <BackgroundContainer>
        <GalleryImage
          as={motion.img}
          src={selectedImage}
          alt="MGK"
          animate={controls}
          style={{
            padding: 0,
            alignSelf: 'center',
            position: 'relative',
            objectFit: 'cover',
          }}
        />
      </BackgroundContainer>
      <InfoSeparatorLogo />
      <ThumbnailContainer ref={NewsthumbnailContainerRef}>
        {item.images.map((img) => (
          <NewsOverlayGalleryThumbnail
            key={img}
            onClick={() => selectThumbnail(img)}
            isSelected={selectedImage === img}
            image={img}
          />
        ))}
      </ThumbnailContainer>
    </GalleryContainer>
  );
}
