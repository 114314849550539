import { ReactElement } from 'react';

import {
  IntroViewContainer, DownArrowContainer, InfoBoxLine,
  IntroInfoBoxText, InfoBoxTextButtonWrapperIntroView,
  TopButtonWrapper,
} from './IntroView.styles';

import InfoBox from '../Common/InfoBox/InfoBox';
import Gallery from './Gallery/Gallery';
import { PropLogo } from './HeroView.styles';
import { FooterLinkSteamLogo, FooterLinkTwitchLogo, FooterLinkDiscordLogo } from '../Common/Button/PromoButton.styles';

import PromoSteamButton from '../Common/Button/PromoSteamButton';
import { SubviewContainer } from './Home.styles';

interface FooterProps {
  scroll: (top: number) => void
}
export default function IntroView({ scroll }: FooterProps): ReactElement {
  function downArrowClick() {
    console.log('DOWN ARROW CLICKED');
    scroll(5000);
  }
  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <SubviewContainer>
      <DownArrowContainer onClick={() => downArrowClick()} />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <InfoBox boxTitle="The Merchant's Guide to The Kingdom">
        <Gallery />
        <InfoBoxLine />
        <InfoBoxTextButtonWrapperIntroView>
          <IntroInfoBoxText>
            <div>
              You are a humble merchant who resides
              in Stonehaven,  the largest of an alliance of
              cities that surround the four elemental stones. This
              alliance is known as The Kingdom. As a veteran storekeeper,
              you make a living buying and selling goods and equipment
              to travelling heroes. Take the necessary steps to advance
              your status and reputation, earning rewards from the King and
              other residents of The Kingdom. Utilize your city’s various artisan stations and
              grow your understanding of the realm’s resources by collecting
              materials to craft your own quality items. Obtain unique and
              exotic goods by managing your transportation and security to
              establish trade routes. Create, recruit, and command your own heroes by forming a
              guild. Equip your heroes to prepare them for everything from quests to solo dungeons,
              rare spawns, world bosses, and massive raids to aid and defend The
              Kingdom.
            </div>
          </IntroInfoBoxText>
          <TopButtonWrapper>
            <PromoSteamButton
              background="#16353F"
              onClick={() => openTab('https://store.steampowered.com/app/2051640/The_Merchants_Guide_to_the_Kingdom/')}
              icon={<FooterLinkSteamLogo />}
              title="Steam"
            />
            <PromoSteamButton
              background="#16353F"
              onClick={() => openTab('https://www.twitch.tv/bearplanelive')}
              icon={<FooterLinkTwitchLogo />}
              title="Twitch"
            />
          </TopButtonWrapper>
          <PromoSteamButton
            background="#16353F"
            onClick={() => openTab('https://discord.gg/2M7tTk7r9g')}
            icon={<FooterLinkDiscordLogo />}
            title="DISCORD"
          />
        </InfoBoxTextButtonWrapperIntroView>
      </InfoBox>
    </SubviewContainer>
  );
}
