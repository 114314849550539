import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseImage } from './img/close.svg';

export const LowerButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  align-self: center;
  position: relative;
  right: 10%;
  bottom:  5%;


@media (min-width: 250px) and (max-width: 900px) {
  bottom:  -45%;
  margin-right: -20%;
 
}
@media (min-width: 901px) and (max-width: 1179px) {
  bottom:  -10%;
  
  
}
@media (min-width: 1180px) and (max-width: 3500px) {
  right:0%;
  
}
`;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  opacity: 1;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 210px;
  background-color: #F0DDC5;
  display: flex;

  &:hover {
    opacity: 0.75;
  };

  @media (max-width: 700px) {
    height: 30px;
    width: 150px;
  }
`;

export const CloseButtonImage = styled(CloseImage)`
  width: 210px;
  height: 44px;

   @media (max-width: 700px) {
    height: 30px;
    width: 150px;
   }
`;
