import { ReactElement, useState } from 'react';
import { AppContainer } from './App.styles';
import AppContext from '../AppContext';
import WalletConnectionProvider from '../Wallet/WalletConnectionProvider';
import Navigation from '../Navigation/Navigation';

function App(): ReactElement {
  const [appLoading, setAppLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        appLoading,
        setAppLoading,
      }}
    >
      <WalletConnectionProvider>
        <AppContainer>
          <Navigation />
        </AppContainer>
      </WalletConnectionProvider>
    </AppContext.Provider>
  );
}

export default App;
