import styled from 'styled-components';
import KingdomImage from './img/Castle_01.png';

export const KingdomContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1;
height: 100%;
position: relative;
justify-content: center;
align-items: center;
/* @media (max-width: 700px) {
  height: 600px;
} */
`;

export const KingdomBGContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    /* margin-right: 62px; */
    /* margin-top: -8px; */
    /* @media (max-width: 700px) {
    height: 480px;
    width: 100%; 
} */
`;

export const KingdomBG = styled.div`
background-image: url(${KingdomImage});
background-repeat: no-repeat;
background-size: contain;
background-position: center;
justify-self: center;
align-self: center;
margin: 20px;
width: 1000px;
height: 1000px;
position: absolute;
z-index: 3;

@media (min-width: 200px) and (max-width: 319px) {
  height: 325px;
  margin-top: -15%;
}
@media (min-width: 320px) and (max-width: 359px) {
  height: 375px;
  margin-top: -15%;
}
@media (min-width: 360px) {
  height: 425px;
  margin-top: -15%;
}
@media (min-width: 390px) {
  height: 455px;
  margin-top: -15%;
}
@media (min-width: 412px) {
  height: 470px;
  margin-top: -15%;
}
@media (min-width: 450px) {
  height: 450px;
  margin-top: -15%;
}
@media (min-width: 575px) {
  height: 500px;
  margin-top: -15%;
}
@media (min-width: 700px) {
  height: 600px;
  margin-top: -10%
}
@media (min-width: 701px) {
  width: 600px;
  height: 600px;
  margin-top: 15%;
}
@media (min-width: 900px) {
  width: 600px;
  height: 600px;
}
@media (min-width: 1000px) {
  width: 750px;
  height: 750px;
  margin-top: -5%;
}
@media (min-width: 1100px) {
  height: 800px;
  margin-top: -15%;
}
@media (min-width: 1300px) {
  width: 1100px;
  height: 1100px;
  margin-top: -13%;
}
@media (min-width: 1500px) {
  width: 1200px;
  height: 1200px;
  margin-top: -15%;
}
@media (min-width: 1600px) {
  width: 1300px;
  height: 1300px;
  margin-top: -15%;
}
@media (min-width: 1700px) {
  width: 1400px;
  height: 1400px;
  margin-top: -15%;
}
@media (min-width: 1800px) {
  width: 1500px;
  height: 1500px;
  margin-top: -15%;
}
`;

export const KingdomTextButtonWrapper = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: flex-end;
align-items: center;
flex-grow: 1;
flex-basis: min-content;
object-fit: contain;
`;

export const KingdomText = styled.div`
flex-grow: 1;
width: 80%;
color: #08161C;
text-align: left;
font-family: SF Pro Display;
text-align: justify;
text-justify: inter-word;
flex-basis: min-content;
object-fit: fill;
align-self: center;
justify-self: center;

div::first-letter {
  float: left;
  font-size: 120px;
  font-weight: normal;
  border: none;
  padding-bottom: 0px;
  margin-right: 15px;
  margin-bottom: -80px;
  margin-top: -30px;
  font-family: blonde-fraktur, serif;
  @media (max-width: 1015px) {
    font-size: 100px;
    margin-bottom: -70px;
    margin-top: -30px;
  }
  @media (max-width: 700px) {
    font-size: 80px;
    margin-bottom: -40px;
    margin-top: -20px;
  }
  @media (max-width: 412px) {
    font-size: 100px;
    margin-bottom: -20px;
    margin-top: -20px;
    margin-right: 10px;
  }
}
div {
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 3500px) {
  font-size: 22px;
  font-weight: bold;
}
@media (max-width: 2000px) {
  font-size: 18px;
  font-weight: bold;
}
  @media (max-width: 1015px) {
  font-size: 15px;
  font-weight: bold;
}
  @media (max-width: 700px) {
  font-size: 12px;
  font-weight: bold;
}
@media (max-width: 430px) {
  font-size: 10px;
  font-weight: bold;
}
}
`;
