import { ReactElement, useEffect, useState } from 'react';
import {
  FooterBackToTop, LinkContainer,
} from './ScrollTo.styles';

export function ScrollTo({ scroll }: FooterProps): ReactElement {
  // const Scroll = (
  //     showBelow
  // ) => {

  // const [show, setShow = useState(showBelow ? false : true)

  //     const handleScroll = () => {
  //         if (window.pageYOffset > showBelow) {
  //                 if(!show) setShow(true)
  //         }else {
  //             if(show) setShow(false)
  //         }
  //         }

  //         useEffect(() => {
  //             if(showBelow) {
  //                 window.addEventListener('scroll', handleScroll)
  //                 return () => window.removeEventListener('scroll', handleScroll)
  //             }
  //         })

  const handleClick = () => {
    console.log('Back to top');
    scroll(0);
  };

  return (
    <LinkContainer>
      <FooterBackToTop onClick={handleClick} />
    </LinkContainer>
  );
}
