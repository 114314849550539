import styled from 'styled-components';

import { ReactComponent as SeparatingLogo } from '../Market/Overlay/img/infoBoxSeparatorLogo.svg';
import { ReactComponent as SelectBackground } from '../Market/Overlay/img/SelectedBorder.svg';
// import { ReactComponent as KingdomSilhouette } from './img/propertyBoxSilhouette.svg';
import Background1 from '../Market/Overlay/img/BGSilh.svg';
// import { ReactComponent as Background2 } from './img/Subtraction 186.svg';

export const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 65%;
    height: 90%;
    object-fit: cover;
    margin-top: 60px;

    @media (min-width: 200px) and (max-width: 900px) {
      width: 98%;
      height: 40%;
      margin-top: 90px;
  }
  `;

export const GalleryImage = styled.img`
    height: 100%;
    width: 100%;
    filter: drop-shadow(0px 6px 10px #947F63);
    align-self: center;
`;

export const BackgroundContainer = styled.div`
  background-image: url(${Background1});
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F0DDC5;
`;

export const ThumbnailContainer = styled.div`
display: flex;
position: relative;
flex-direction: row;
justify-content: space-between;
max-width: 25%;
max-height: 25%;
object-fit: cover;

&::-webkit-scrollbar {
display: none; }
`;

export const InfoSeparatorLogo = styled(SeparatingLogo)`
opacity: 50%;
height: 40px;
`;
