import { ReactElement } from 'react';
import {
  MerchantLink, GuideLink, KingdomLink, LinkContainer,
} from './ScrollToSection.styles';

interface FooterProps {
  scroll: (top: number) => void
}

export function ScrollToSection({ scroll }: FooterProps): ReactElement {
  const handleMerchantClick = () => {
    console.log('Merchant');
    scroll(1480);
  };

  const handleGuideClick = () => {
    console.log('Guide');
    scroll(2400);
  };

  const handleKingdomClick = () => {
    console.log('Kingdom');
    scroll(3300);
  };

  return (
    <LinkContainer>
      <MerchantLink to="" onClick={handleMerchantClick}>THE MERCHANT</MerchantLink>
      <GuideLink to="" onClick={handleGuideClick}>THE GUIDE</GuideLink>
      <KingdomLink to="" onClick={handleKingdomClick}>THE KINGDOM</KingdomLink>
    </LinkContainer>
  );
}
