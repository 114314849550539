import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MerchantLink = styled(Link)`
 text-decoration: none;
 color: #6c7e84;
 font-family: sofia-pro, sans-serif;
 font-style: normal;
 font-weight: 600;
 font-size: 20;
 margin-bottom: 0.5rem;

 &:hover {
    color: #FFEC6E;
    transition: 0.3s ease-out
  }
`;

export const GuideLink = styled(Link)`
 text-decoration: none;
 color: #6c7e84;
 font-family: sofia-pro, sans-serif;
 font-style: normal;
 font-weight: 600;
 font-size: 20;
 margin-bottom: 0.5rem;

 &:hover {
    color: #FFEC6E;
    transition: 0.3s ease-out
  }
`;

export const KingdomLink = styled(Link)`
 text-decoration: none;
 color: #6c7e84;
 font-family: sofia-pro, sans-serif;
 font-style: normal;
 font-weight: 600;
 font-size: 20;
 margin-bottom: 0.5rem;

 &:hover {
    color: #FFEC6E;
    transition: 0.3s ease-out
  }
`;

export const LinkContainer = styled.div`
display: flex;
flex-direction: column;
text-align: left;
color #fff;
font-size: 20;
font-family: sofia-pro, sans-serif;
font-style: normal;
font-weight: 600;
`;
