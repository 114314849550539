import { ReactElement, useRef } from 'react';
import { HomeContainer } from './Home.styles';

import HeroView from './HeroView';
import IntroView from './IntroView';
import MerchantView from './MerchantView';
import GuideView from './GuideView';
import KingdomView from './KingdomView';
import Footer from '../Footer/Footer';

export default function Home(): ReactElement {
  const homeContainerRef = useRef<HTMLInputElement>(null);

  function homeScroll(top = 0) {
    console.log('scroll');
    homeContainerRef.current.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <HomeContainer ref={homeContainerRef}>
      <HeroView />
      <IntroView scroll={homeScroll} />
      <MerchantView />
      <GuideView />
      <KingdomView />
      <Footer scroll={homeScroll} />
    </HomeContainer>
  );
}
