import { Cluster } from '@solana/web3.js';

import ItemData from './itemdata.json';
import NewsData from './newsdata.json';
import { MGKItem } from './types/MGK';
import { MGKNEWS } from './types/MGKN';

const {
  NEXT_PUBLIC_NETWORK,
} = process.env;

export const NETWORK: Cluster = NEXT_PUBLIC_NETWORK as Cluster || 'devnet';
export const MGKItems: MGKItem[] = ItemData;
// export const MGKNEWSItems: MGKNEWS[] = NewsData;
