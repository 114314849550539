import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #E7D4BC;
  
  height: calc(100vh - 76px);
  margin-top: 76px;
  `;

export const PPTextContainer = styled.div`
 width: 60vw;
 height: 100%;
 margin: 30px;
 overflow-y: scroll;
 border: none;
 border-radius: 10px 10px 10px 10px;
 cursor: text;
 transition: all, 240ms ease-in-out;
 align-items: left;
 justify-content: left;
 background: #193540;
`;

export const PrivacyPolicyText = styled.div`
color: #fff;
font-size: 15px;
font-weight: 600;
padding: 5px;
`;

export const Break = styled.div`
  height: 10px;
`;
