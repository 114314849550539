import { ReactElement } from 'react';
// import ReactThreeFbxViewer from 'react-three-fbx-viewer';
// import ModelViewer from 'react-model-viewer';

import InfoBox from '../Common/InfoBox/InfoBox';
import PromoButton from '../Common/Button/PromoButton';
import { PropLogo } from './HeroView.styles';
import { SubviewContainer } from './Home.styles';
import { InfoBoxTextButtonWrapperMerchantGuide, InfoBoxText } from './IntroView.styles';
import { TheGuide, ImageWrapper } from './GuideView.styles';
import { Silhouette } from './MerchantView.styles';
import { SteamButtonLogo } from '../Common/Button/PromoButton.styles';

// import TheGuide from './img/book_02.png';

export default function GuideView(): ReactElement {
  // const fbxUrl = require('./img/Book.fbx');
  function openTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  // const cameraPosition = {
  //   x: 150,
  //   y: 300,
  //   z: 350,
  // };

  const imgStyle = {
    width: 1500,
    height: 1500,
    cursor: 'pointer',
    margin: '-500px',
  };

  return (
    <SubviewContainer>
      <InfoBox boxTitle="The Guide" boxColor="#242E2E" justify="left">
        <ImageWrapper>
          {/* <div>
            <ReactThreeFbxViewer
              cameraPosition={cameraPosition}
              url={fbxUrl}
            />
          </div> */}
          {/* <ModelViewer
            bounds="tight"
            src="OldBook (3).glb"
            ar
            ar-modes="webxr scene-viewer quick-look"
            camera-controls
            environment-image="neutral"
            poster="poster.webp"
            shadow-intensity="1"
          >
            <div className="progress-bar hide" slot="progress-bar">
              <div className="update-bar" />
            </div>
            <button slot="ar-button" id="ar-button">
              View in your space
            </button>
            <div id="ar-prompt">
              <img src="https://modelviewer.dev/shared-assets/icons/hand.png" alt="" />
            </div>
          </ModelViewer> */}
          {/* <TheGuide>
            {' '}
            <img
              src={require(TheGuide)}
              alt=""
              style={imgStyle}
            />
          </TheGuide> */}
          <Silhouette />
          <TheGuide />
        </ImageWrapper>
        <InfoBoxTextButtonWrapperMerchantGuide>
          <InfoBoxText>
            <div>
              True peace in The Kingdom does not come without a thorough understanding of the
              needs of the people. The Guide is your family’s centuries of findings and
              research of the surrounding lands and depicts the importance of preservation of
              the elemental stones. As you command your brigade, you will unearth new
              discoveries, treasures, and ancient relics of the past that will drive your
              actions and expand the myriad of knowledge in The Guide. Each action you take
              will increase your experience and enhance your abilities. Utilize this experience
              to uniquely specialize your talents as a Commander, Director, Merchant, Ambassador,
              or Craftsman. The decisions among talents and devoted individuals to join your
              brigade will greatly affect your path towards success. Work towards increasing your
              forces and gain strength against those who seek to disrupt the stones while devoting
              your research to The Guide. There are many great beings who have come before you
              and will proceed you… decide your legacy.
            </div>
          </InfoBoxText>
          <PromoButton
            background="#16353F"
            onClick={() => openTab('https://store.steampowered.com/app/2051640/The_Merchants_Guide_to_the_Kingdom/')}
            icon={<SteamButtonLogo />}
            title="STEAM"
          />
        </InfoBoxTextButtonWrapperMerchantGuide>
      </InfoBox>
    </SubviewContainer>
  );
}
