import { createContext, Dispatch, SetStateAction } from 'react';

export interface IAppContext {
  appLoading: boolean;
  setAppLoading: Dispatch<SetStateAction<boolean>>;
}

const AppContext = createContext({} as IAppContext);

export default AppContext;
